import {FC, createContext, useEffect } from 'react'

import data from '../content/pages/header.json'

export interface HeaderContextInterface  {
    items: Item[];
}

export interface Submenu {
    name: string;
    link: string;
}

export interface Item {
    text: string;
    type: string;
    to: string;
    styleType: string;
    submenu: Submenu[];
    animationText?: string;
}

const HeaderContext = createContext<HeaderContextInterface | null>(data);

export const HeaderProvider: FC<any> = ({ children }) => { 

  //const [items, setItems] = useState<Item[]>([]);


  useEffect(() => {

    // const fetchData = async () => {
    //   const response = await fetch(
    //     'https://raw.githubusercontent.com/DFGPangolinoConservation/Pangolino-Content/main/pages/header.json'
    //   )
    //   const data: HeaderContextInterface = await response.json()
  
    //   initialLoad(data)
    // }
  
    // // This function is called when the page is first loaded
    // function initialLoad(data: HeaderContextInterface){
    //   setItems(data.items)
    // }
    
    // fetchData()
  }, [])
 

  return (
    <HeaderContext.Provider
      value={data}
    >
      {children}
      
    </HeaderContext.Provider>
  )
}

export default HeaderContext
