import  { useContext } from 'react';
import { CustomButton } from '../../components/buttons/Buttons';
import Page404Context, { Page404ContextInterface } from '../../context/404Context';
import './404.css';
import { Helmet } from 'react-helmet-async';


export function Page404() : JSX.Element{

  const { top, description, button } = useContext(
    Page404Context
  ) as Page404ContextInterface;



  return(
    <div className='Page404'>
        {/* @ts-ignore */}
        <Helmet>
              <title>Page not found (404) - Pangolino.org</title>
              <meta name="description" content='The page does not exist.'></meta>
              <link rel="canonical" href="/404"></link>
        </Helmet>
        <div className='Page404-Body'>
            <h1>{top}</h1>
            <p>{description}</p>
            <div>
                <CustomButton
                    text={button} 
                    type="link"
                    styleType='filled-inversed'
                    to="/"   
                >
                    {button}
                </CustomButton>
            </div>
        </div>
    </div>
  )
}
