import {FC, createContext, useState, useEffect } from 'react'

import data from '../content/pages/contact_us.json'

export interface ContactContextInterface  {
    top: Top;
    form: Form;
    sendMessage: (firstName: string, lastName: string, email: string, subject: string, message: string) => void;
    formValues: FormProps
}

export interface Top {
    title: string;
    description: string;
}

export interface Name {
    label: string;
    first: string;
    last: string;
}

export interface Form {
    title: string;
    name: Name;
    email: string;
    subject: string;
    message: string;
    submit_button: string;
}

export interface FormProps{
    sent: boolean;
    notcomplete: boolean;
    error: boolean;
    description: string;
}

const ContactContext = createContext<ContactContextInterface | null>(null);

export const ContactProvider: FC<any> = ({ children }) => { 

    const top : Top = data.top;

    const form : Form = data.form;


    const [formValues, setFormValues] = useState<FormProps>({
        sent: false,
        notcomplete: true,
        error: false,
        description: ''
    });


    useEffect(() => {

        // const fetchData = async () => {
        //     const response = await fetch(
        //     'https://raw.githubusercontent.com/DFGPangolinoConservation/Pangolino-Content/main/pages/contact_us.json'
        //     )
        //     const data: ContactContextInterface = await response.json()
    
        //     initialLoad(data)
        // }
    
        // // This function is called when the page is first loaded
        // function initialLoad(data: ContactContextInterface){
        //     setTop(data.top)
        //     setForm(data.form)
        // }
        
        // fetchData()
    }, [])

    function sendMessage(firstName: string, lastName: string, email: string, subject: string, message: string){
        //check if inputs are empty or undefined
        if(firstName === '' || lastName === '' || email === '' || subject === '' || message === ''){
            setFormValues({
                sent: false,
                notcomplete: true,
                error: false,
                description: 'Please fill out all fields'
            })
            return
        }

        const api = "https://backend.pangolino.org/contact?fname=" + firstName + "&lname=" +  lastName 
        + "&email=" + email + "&subject=" + subject + "&message=" + message

        fetch(api)
          .then(response => {
            if (response.status === 200) {
                setFormValues({
                    sent: true,
                    notcomplete: false,
                    error: false,
                    description: 'Message sent!'
                })
            } else {
                setFormValues({
                    sent: false,
                    notcomplete: false,
                    error: true,
                    description: 'Error sending message, please try again'
                })
            }
          })
    }

    return (
        <ContactContext.Provider
        value={{
            top, form, sendMessage, formValues
            
        }}
        >
        {children}
        
        </ContactContext.Provider>
    )
}

export default ContactContext
