import {FC, createContext } from 'react'
import data from '../content/pages/404.json'

export interface Page404ContextInterface  {
  top: string,
  description: string,
  button: string,
}

const Page404Context = createContext<Page404ContextInterface>(data);

export const Page404Provider: FC<any> = ({ children }) => { 

    // const [top, setTop] = useState<string>("404");
    // const [description, setDescription] = useState<string>("Loading...");
    // const [button, setButton] = useState<string>("Back to Home Page");



  // useEffect(() => {
  //   const fetchData = async () => {
  //     const response = await fetch(
  //       'https://raw.githubusercontent.com/DFGPangolinoConservation/Pangolino-Content/main/pages/404.json', 
  //       {method: "GET"}
  //     )
  //     const data: Page404ContextInterface = await response.json()
  
  //     initialLoad(data)
  //   }
    
  //   fetchData()
  // }, [])


  // // This function is called when the page is first loaded
  // function initialLoad(data: Page404ContextInterface){
  //   setTop(data.top)
  //   setDescription(data.description)
  //   setButton(data.button)
  // }
 

  return (
    <Page404Context.Provider
      value={data}
    >
      {children}
      
    </Page404Context.Provider>
  )
}

export default Page404Context
