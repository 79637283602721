import {FC, createContext, useEffect } from 'react'

import data from '../content/pages/main.json'


export interface MainContextInterface  {
    top: Top;
    subtop: ContainerLR;
    numbers: Numbers;
    body: ContainerLR[];
    testimonials: Testimonials;
}

export interface Top {
    title: string;
    description: string;
    button_1_text: string;
    button_1_link: string;
    button_2_text: string;
    button_2_link: string;
    gif: string;
}

export interface Stat {
    number: string;
    description: string;
}

export interface Numbers {
    title: string;
    description?: string;
    stats: Stat[];
}

export interface ContainerLR {
    label: string;
    title: string;
    description: string;
    button_1_text: string;
    button_1_link: string;
    button_2_text: string;
    button_2_link: string;
    img: string;
    position_left: boolean;
    mobileTopTitle?: boolean;
}

export interface Testimonials{
    title: string;
    items: Testimonial[];
}

export interface Testimonial {
    name: string;
    country: string;
    text: string;
}

const MainContext = createContext<MainContextInterface | null>(data);

export const MainProvider: FC<any> = ({ children }) => { 

    // const top: Top = data.top

    // const subtop = data.subtop

    // const numbers: Numbers = data.numbers

    // const body: ContainerLR[] = data.body

    // const testimonials: Testimonials = data.testimonials


    useEffect(() => {

        // const fetchData = async () => {
        //     const response = await fetch(
        //     'https://raw.githubusercontent.com/DFGPangolinoConservation/Pangolino-Content/main/pages/main.json'
        //     )
        //     const data: MainContextInterface = await response.json()
    
        //     initialLoad(data)
        // }
    
        // // This function is called when the page is first loaded
        // function initialLoad(data: MainContextInterface){
        //     setTop(data.top)
        //     setSubtop(data.subtop)
        //     setNumbers(data.numbers)
        //     setBody(data.body)
        //     setTestimonials(data.testimonials)
        //     console.log(data)
        // }
        
        // fetchData()
    }, [])

    

    return (
        <MainContext.Provider
        value={data}
        >
        {children}
        
        </MainContext.Provider>
    )
}

export default MainContext
