import './Numbers.css'
import { Numbers as NumbersInterface, Stat as StatsInterface } from '../../../context/MainContext'

const Statistics: React.FC<StatsInterface> = (props) => {
    return(
        <div className='MainBody-NumbersContainer-FlexBox-StatsItem'>
            <h1>{props.number}</h1>
            <p>{props.description}</p>
        </div>
    )
}

export const Numbers: React.FC<NumbersInterface> = (props) => {
    return(
        <div className='MainBody-NumbersContainer'>
            <h1>{props.title}</h1>
            <div className='MainBody-NumbersContainer-FlexBox'>
                {props.stats.map((stat: StatsInterface, index: number) => {
                    return(<Statistics {...stat} key={index}/>)
                })}
            </div>
        </div>
    )
}