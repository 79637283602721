import { FC, createContext, useEffect } from "react";

import data from '../content/pages/footer.json'

export interface SocialMedia {
  name: string;
  link: string;
  icon: string;
}

export interface FirstCard {
  logo_url: string;
  text: string;
  social_media: SocialMedia[];
}

export interface Item {
  title: string;
  link: string;
}

export interface OtherCard {
  title: string;
  items: Item[];
}

export interface Main {
  first_card: FirstCard;
  other_cards: OtherCard[];
}

export interface Item2 {
  title: string;
  link: string;
}

export interface Second {
  title: string;
  items: Item2[];
}

export interface SocialMedia2 {
  name: string;
  link: string;
  icon: string;
}

export interface Contact {
  title: string;
  title_link: string;
  social_media: SocialMedia2[];
}

export interface Item3 {
  title: string;
  link: string;
}

export interface Legal {
  title: string;
  title_link: string;
  items: Item3[];
}

export interface Item4 {
  title: string;
  link: string;
}

export interface More {
  title: string;
  title_link: string;
  items: Item4[];
}

export interface Mobile {
  contact: Contact;
  legal: Legal;
  more: More;
}

export interface FooterContextInterface {
  main: Main;
  second: Second;
}

const FooterContext = createContext<FooterContextInterface | null>(data);

export const FooterProvider: FC<any> = ({ children }) => {
  // const [main, setMain] = useState<Main>({
  //   first_card: {
  //     logo_url: "",
  //     text: "",
  //     social_media: [
  //       {
  //         name: "",
  //         link: "",
  //         icon: "",
  //       },
  //     ],
  //   },
  //   other_cards: [
  //     {
  //       title: "",
  //       items: [
  //         {
  //           title: "",
  //           link: "",
  //         },
  //       ],
  //     },
  //   ],
  // });

  // const [second, setSecond] = useState<Second>({
  //   title: "",
  //   items: [
  //     {
  //       title: "",
  //       link: "",
  //     },
  //   ],
  // });

  useEffect(() => {

    // const fetchData = async () => {
    //   const response = await fetch(
    //     "https://raw.githubusercontent.com/DFGPangolinoConservation/Pangolino-Content/main/pages/footer.json"
    //   );
    //   const data: FooterContextInterface = await response.json();
  
    //   initialLoad(data);
    // };
  
    // // This function is called when the page is first loaded
    // function initialLoad(data: FooterContextInterface) {
    //   setMain(data.main);
    //   setSecond(data.second);
    //   console.log(data);
    // }
    
    // fetchData();
  }, []);

  return (
    <FooterContext.Provider
      value={data}
    >
      {children}
    </FooterContext.Provider>
  );
};

export default FooterContext;
