import './Footer.css';
import FooterImage from './assets/geometry/footer.svg';
import FooterContext, { FooterContextInterface } from './context/FooterContext';
import { useContext } from 'react'; 
import { Link } from 'react-router-dom';

const Divider = () => {
    return (
        <svg width="100%" height="1px">
            <line x1="100%" y1="100%" x2="0%" y2="100%" stroke="#ECE5D1" strokeWidth="1"></line>
        </svg>
    )
}

export function Footer(): JSX.Element {

  const { main, second } = useContext(FooterContext) as FooterContextInterface;

  

  return (
      <div className='Footer'>
        <div className="FooterD">
            <img className='FooterD-Image' alt='Pangolino Logo' src={FooterImage}></img>
            <div className="FooterD-MainContainer">
                <div className="FooterD-Card" id='FooterD-Card-Main'>
                        <img src={main.first_card.logo_url} alt="Pangolin logo" />
                        <p className='className="FooterD-Card-Item"'>{main.first_card.text}</p>
                </div>
                <div className="FooterD-GroupOf-Cards">
                    {main.other_cards.map((card, index) => (
                                <div className="FooterD-Card" key={index}>
                                    <div>
                                        <h1>{card.title}</h1>
                                        <div className='FooterD-Card'>
                                        {card.items.map((item, index) => (
                                            <Link to={item.link}>
                                                <div className="FooterD-Card-Item" key={index}>
                                                    <span>{item.title}</span>
                                                </div>
                                            </Link>
                                        ))}
                                        </div>
                                    </div>
                                </div>  
                    ))}
                </div>
            </div>
            <Divider></Divider>
            <div className="FooterD-SecondContainer">
                    <div className="FooterD-SecondContainer-Left">
                        <h1>{second.title}</h1>
                        {second.items.map((item, index) => {
                            return(
                                <>
                                <div className="FooterD-SecondContainer-Item">|</div>
                                <div className="FooterD-SecondContainer-Item" key={index}>
                                    {item.link.length > 10 && <a href={ "https://pangolino.org/" + item.link} target="_blank" rel="noopener noreferrer">{item.title}</a>}
                                    {item.link.length <= 10 && <Link to={item.link}><span>{item.title}</span></Link>}
                                </div>
                                </>)
                        })}
                    </div>
                    <div className="FooterD-SecondContainer-Right">
                        <div className="FooterD-Card-SocialMedia">
                            {main.first_card.social_media.map((social_media, index) => (
                                <a key={index} target="_blank" rel="noopener noreferrer" href={social_media.link}>
                                    <img src={social_media.icon} alt={social_media.name} />
                                </a>
                            ))}
                        </div>
                    </div>
            </div>
        </div>
      </div>
  )
}