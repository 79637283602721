import  { useContext } from 'react';
import HeaderContext, { HeaderContextInterface } from './../context/HeaderContext';
import { TitleSection2 } from '../components/generic/TitleSection2';
import './Sitemap.css';

export function Sitemap() : JSX.Element{

  const items = useContext(HeaderContext) as HeaderContextInterface;

  return(
    <div className='Sitemap'>
        <TitleSection2 title={'Sitemap'} description={''} />
        <div className='Sitemap-Body'>
        {items.items.map((card, index) => (
            <div className="Sitemap-Container" key={index}>
                <div>
                    <div className='Sitemap-Title'>
                        {card.to !== "" && <li><a href={card.to}className='Sitemap-Title-H1'>{card.text}</a></li>}
                        {card.to === "" && <li><span className='Sitemap-Title-H1'>{card.text}</span></li>}
                    </div>
                    <div className='Sitemap-Items-Container'>
                        
                    {card.submenu.map((item, index) => (
                        <ul className="Sitemap-Item" key={index}>
                            <li><a href={item.link}>{item.name}</a></li>
                        </ul>
                    ))}
                    </div>
                </div>
            </div>  
            ))}    
        </div>
    </div>
  )
}
