import {FC, createContext, useEffect } from 'react'

import data from '../content/pages/pangolin_facts.json'

export interface PangolinFactsContextInterface  {
  top: Top;
  body: Body;
  facts: Facts;
}

export interface Top {
  title: string;
  description: string;
}

export interface Body {
  title: string;
  description: string;
  description_image: string;
}

export interface Facts {
  title: string;
  description: string;
  facts: string[];
  images_basis: string;
  images: string[];
}

const PangolinFactsContext = createContext<PangolinFactsContextInterface | null>(data);

export const PangolinFactsProvider: FC<any> = ({ children }) => { 

  // const [top, setTop] = useState<Top>(
  //   {
  //     title: "Loading...",
  //     description: "Loading..."
  //   }
  // )
  // const [body, setBody] = useState<Body>(
  //   {
  //     title: "Loading...",
  //     description: "Loading...",
  //     description_image: "Loading..."
  //   }
  // )
  // const [facts, setFacts] = useState<Facts>({
  //   title: "Loading...",
  //   description: "Loading...",
  //   facts: ["Loading..."],
  //   images_basis: "URL",
  //   images: ["Loading..."]
  // });

  useEffect(() => {

    // const fetchArticles = async () => {
    //   const response = await fetch(
    //     'https://raw.githubusercontent.com/DFGPangolinoConservation/Pangolino-Content/main/pages/pangolin_facts.json'
    //   )
    //   const data: PangolinFactsContextInterface = await response.json()
  
    //   initialLoad(data)
    // }
  
    // // This function is called when the page is first loaded
    // function initialLoad(data: PangolinFactsContextInterface){
    //   setTop(data.top)
    //   setBody(data.body)
    //   setFacts(data.facts)
    //   console.log(data)
    // }

    // fetchArticles()
  }, [])
 

  return (
    <PangolinFactsContext.Provider
      value={data}
    >
      {children}
      
    </PangolinFactsContext.Provider>
  )
}

export default PangolinFactsContext
