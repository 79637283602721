import React, { useEffect, Fragment } from 'react';
import { useLocation } from "react-router-dom";
import { scroller } from 'react-scroll'

export default function ScrollToTop({ children }) {
  const { pathname } = useLocation();

  useEffect(() => {
    
    scroller.scrollTo('root', {
      duration: 200,
      delay: 100,
      smooth: 'easeInOutQuart',
    })

  }, [pathname]);

  return <Fragment>{children}</Fragment>;
}
