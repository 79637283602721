import {FC, createContext, useState, useEffect } from 'react'

import data from '../content/pages/blog.json'

export interface Top {
    title: string;
    description: string;
}

export interface Item {
    title: string;
    description: string;
    link: string;
    date: string;
    tag: string;
    external: boolean;
    sortID: number;

    post_image?: string,
    author?: string;
    author_title?: string;
    author_description?: string;
    author_image?: string;
}

export interface Publications {
    title: string;
    items: Item[];
}

export interface Media {
    title: string;
    items: Item[];
}


export interface Blogs {
    title: string;
    items: Item[];
}

export interface BlogContextInterface {
    top: Top;

    noItemsFirstLine: string;
    noItemsSecondLine: string;

    invalid_date: string;
    blog_images_folder: string;
    markdown_folder: string;
    publications: Publications;
    media: Media;
    blogs: Blogs;

    pageCounter: number[];
    itemsToShow: Item[];
    ITEMS_PER_PAGE: number;
    totalItems: number;
}


const BlogContext = createContext<BlogContextInterface | null>(null);

export const BlogProvider: FC<any> = ({ children }) => { 

  const top: Top = data.top

  const noItemsFirstLine : string = data.noItemsFirstLine;
  const noItemsSecondLine : string = data.noItemsSecondLine;

  const invalid_date : string = data.invalid_date

  const markdown_folder : string = data.markdown_folder
  const blog_images_folder : string = data.blog_images_folder


  const publications : Publications = data.publications

  const media : Media = data.media

  const blogs : Blogs = data.blogs

  const [pageCounter, setPageCounter] = useState<number[]>([]);
  const [itemsToShow, setItemsToShow] = useState<Item[]>([]);
  const ITEMS_PER_PAGE = data.ITEMS_PER_PAGE;
  const [totalItems, setTotalItems] = useState<number>(0);
  

 
  useEffect(() => {

    const ITEMS_PER_PAGE = data.ITEMS_PER_PAGE;


    function setAllPages(everything: Item[]){
      let temp: [number] = [-1];
      for ( let i = 0; i < everything.length ; i++ ) {
        if (i % ITEMS_PER_PAGE === 0) {
          temp.push(i / ITEMS_PER_PAGE + 1);
        }
      }
      temp.shift()
      setPageCounter(temp);
    }
  
    // This function is called when the page is first loaded
    function initialLoad(){
    
      let temp = [...data.publications.items, ...data.blogs.items, ...data.media.items]
      console.log(temp)
      let temp2 = temp.sort( function(a, b) {
        return b.sortID - a.sortID;
      })
      console.log(temp2)
      setItemsToShow(temp2)
      setAllPages(temp2)
      setTotalItems(data.publications.items.length + data.blogs.items.length + data.media.items.length)
    }

    initialLoad()
  }, [])

 

  return (
    <BlogContext.Provider
      value={{
        top,
        noItemsFirstLine,
        noItemsSecondLine,
        invalid_date,
        blog_images_folder,
        markdown_folder,
        publications,
        media,
        blogs,

        pageCounter,
        itemsToShow,
        ITEMS_PER_PAGE,
        totalItems,
      }}  
    >
      {children}
      
    </BlogContext.Provider>
  )
}

export default BlogContext

