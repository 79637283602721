import './Top.css'
import { Top as TopInterface } from '../../../context/MainContext';

export const Top: React.FC<TopInterface> = (props) => {
    return(
        <div className='MainBody-Top'>
            <div className='MainBody-Top-Content'>
                <h1>{props.title}</h1>
                <p>{props.description}</p>
                {/* <div>
                    <CustomButton
                        text={props.button_1_text}
                        type="link"
                        to={props.button_1_link}
                        styleType="filled"
                        mobileFullLength={true}
                    />               
                    <CustomButton
                        text={props.button_1_text}
                        type="link"
                        to={props.button_1_link}
                        styleType="unfilled-outlined"
                        mobileFullLength={true}
                    />
                </div> */}
            </div>
            <img src={props.gif} alt='Pangolino Animation'/>
        </div>
    )
}
