import "./communitycon.css";
import { TitleSection2 } from "../../components/generic/TitleSection2";
import { useContext } from "react";
import CommunityConContext, {
  CommunityConContextInterface,
} from "../../context/CommunityConContext";
import { Helmet } from 'react-helmet-async';

export function CommunityCon(): JSX.Element {
  const { top, body } = useContext(
    CommunityConContext
  ) as CommunityConContextInterface;

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
          <title>Community Conservation - Pangolino.org</title>
          <meta name="description" content='Community-based interventions has great potential to reduce pangolin decline and curb their trafficking.'></meta>
          <link rel="canonical" href="/community"></link>
      </Helmet>

      <TitleSection2 title={top.title} description={top.description} />
      <div className="CommunityConservation-Body">
        <div>
          <div className="CommunityConservation-Description">
            {body.description}
          </div>
        </div>
      </div>
    </>
  );
}
