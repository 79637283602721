import './Header.css'
import Logo from './assets/logo/primary/logo-primary-monochromo-white.svg'
import { CustomButton } from './components/buttons/Buttons'
import { Link } from "react-router-dom";
import { Menu } from 'antd';
import MobileHeaderLogo from "./assets/logo/mark-only/symbol-monochromo-white.svg";
import MobileHeaderIcon from "./assets/geometry/mobile-header.svg"
import { Drawer } from 'antd';
import { useContext, useState } from 'react';
import HeaderContext, {HeaderContextInterface} from './context/HeaderContext';
import closePapyrus from './assets/geometry/closePapyrus.svg'

export function Header() : JSX.Element{

    const items = useContext(HeaderContext) as HeaderContextInterface;

    const [drawerVisible, setDrawerVisible] = useState(false);

    let maxIndex = 1;
    let maxDesktopIndex = 1;

    const [currnetItems, setCurrentItems] = useState(["0"]);

    const showDrawer = () => {
      setDrawerVisible(true);
    };


    const onCloseWithoutItem = (save: boolean) => {
      if (save) {
        setCurrentItems(currnetItems);
      } else {
        setCurrentItems(["0"]);
      }
      setDrawerVisible(false);
    }

    const onClose = (ind: number) => {
      const str = ind.toString();
      setCurrentItems([str]);
      setDrawerVisible(false);
    };

    const { SubMenu } = Menu;
  
    return(
        <>
            <nav className="Header-desktop">
                {/* Home */}
                <div className="Header-desktop-left">
                    <Link to="/" className="Header-desktop-logo" >
                        <img src={Logo} className="Header-desktop-logo-img" alt="Pangolino Logo"/>
                        <img src={MobileHeaderLogo} className="Header-desktop-logo-mobile-img" alt="Pangolino Logo"/>
                    </Link>
                </div>
                {/* Who We Are */}
                <div className="Header-desktop-right">
                  {items.items.map(
                    (item, index) => {
                      const curIndex = maxDesktopIndex;
                      let indexArray = []
                      if (item.submenu.length !== 0){
                        for (let i = 0; i < item.submenu.length; i++) {
                          indexArray.push(curIndex + i)
                          maxDesktopIndex++
                        }
                      } else {
                        maxDesktopIndex++
                      }
                      return(
                         <span onClick={() => item.submenu.length === 0 ? onClose(curIndex) : onCloseWithoutItem}>
                            <CustomButton 
                              key={index}
                              text={item.text} 
                              type={item.type}
                              to={item.to}
                              styleType={item.styleType}
                              menu={item.submenu}
                              animationText={item.animationText}
                              setOfIndexes={indexArray}
                              setCurrentItems={setCurrentItems}
                            />
                        </span>)
                    })
                  }
                </div>
            </nav>
            <nav className="Header-mobile">
                <Link to="/" className='Header-mobile-logo'>
                  <img src={MobileHeaderLogo} alt="Pangolino Logo"/>
                </Link>
                <img src={MobileHeaderIcon} className="Header-mobile-icon" alt="Menu" onClick={showDrawer}/>
                <Drawer drawerStyle={{backgroundColor: "var(--moss)"}} headerStyle={{backgroundColor: "var(--moss)"}} title="" placement="right" onClose={() => onCloseWithoutItem(true)} visible={drawerVisible} width={"100vw"} closeIcon={<img alt="Close Button" src={closePapyrus}></img>}>
                <Link to="/" onClick={() => onCloseWithoutItem(false)}>
                  <img src={MobileHeaderLogo} className='Header-mobile-logo-hidden' alt="Pangolino Logo"/>
                </Link>
                  <Menu
                    defaultSelectedKeys={[]}
                    defaultOpenKeys={[]}
                    selectedKeys={currnetItems}
                    mode="inline"
                    theme="dark"
                  >
                    {items.items.map(
                      (item, index) => {
                        if (item.submenu.length === 0) {
                          if (item.styleType === "unfilled-header") {
                            const curIndex = maxIndex;
                            maxIndex = maxIndex + 1;
                            return(
                              <Menu.Item key={curIndex}>
                                <Link to={item.to} onClick={() => onClose(curIndex)}></Link>
                                {item.text} 
                              </Menu.Item>)
                          } else {
                            maxIndex = maxIndex + 1;
                            return(
                            <div>
                              <div className="Header-mobile-donate" onClick={() => () => onCloseWithoutItem(true)}>
                                <CustomButton 
                                      text={item.text}
                                      type={item.type}
                                      styleType={item.styleType}
                                      to={item.to}
                                      animationText={item.animationText}
                                />
                              </div>
                            </div>)
                          }
                        } else {
                          return(
                            <SubMenu key={"sub" + index} title={item.text}>
                              {item.submenu.map((item, subindex) => {
                                const curIndex = maxIndex;
                                maxIndex = maxIndex + 1;
                                return(
                                <Menu.Item key={curIndex}>
                                  <Link to={item.link} onClick={() => onClose(curIndex)}>{item.name}</Link>
                                </Menu.Item>
                              )})}
                            </SubMenu>)
                        }
                      })
                    }
                  </Menu>
                </Drawer>
            </nav>
        </>
    )
}
