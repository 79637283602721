import { FC, createContext, useState, useEffect } from "react";

import data from '../content/pages/which_pangolin.json'

export interface WhichPangolinContextInterface {
  top: Top;
  submit: string;
  cards: Card[];
  selectedId: number;
  selectFunc: (id: number) => void;
  submitFunc: (id: number) => void;
  isSubmitted: boolean;
  stats:  Stats;
  share: Share;
}

export interface Top {
  title: string;
  description: string;
}

export interface Card {
  id: number;
  title: string;
  img: string;
  facts: string[];
}

export interface Stats {
  pangolins: [
    {
      name: string;
      num: number;
    },
    {
      name: string;
      num: number;
    },
    {
      name: string;
      num: number;
    },
    {
      name: string;
      num: number;
    },
    {
      name: string;
      num: number;
    },
    {
      name: string;
      num: number;
    }
    
  ]
  total: number;
}

export interface Share {
  share_title: string;
  share_text: string;
  share_img: string;
  share_url: string;
  share_link_img: string;
  share_fb_img: string;
  share_tw_img: string;
}

const WhichPangolinContext =
  createContext<WhichPangolinContextInterface | null>(null);

export const WhichPangolinProvider: FC<any> = ({ children }) => {


  const top: Top = data.top
  const cards: Card[] = data.cards
  const submit: string = data.submit
  const [selectedId, setSelectedId] = useState<number>(-1);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const [stats, setStats] = useState<Stats>({
    pangolins: [
      {
        name: "Loading...",
        num: 0,
      },
      {
        name: "Loading...",
        num: 0,
      },
      {
        name: "Loading...",
        num: 0,
      },
      { 
        name: "Loading...",
        num: 0,
      },
      {
        name: "Loading...",
        num: 0,
      },
      {
        name: "Loading...",
        num: 0,
      }
    ],
    total: 0,
  });

  const share: Share = data.share

  useEffect(() => {

    // const fetchData = async () => {
    //   const response = await fetch(
    //     "https://raw.githubusercontent.com/DFGPangolinoConservation/Pangolino-Content/main/pages/which_pangolin.json"
    //   );
    //   const data: WhichPangolinContextInterface = await response.json();
  
    //   initialLoad(data);
    // };
  
    // // This function is called when the page is first loaded
    // function initialLoad(data: WhichPangolinContextInterface) {
    //   setTop(data.top);
    //   setCards(data.cards);
    //   setSubmit(data.submit);
    //   setShare(data.share);
    //   console.log(data);
    // }

    // fetchData();
  }, []);



  function selectFunc(id: number) {
    setSelectedId(id);
  }

  //  -1 - {none}
  //   1 - lazy acorn
  //   2 - climbing cactus
  //   3 - breathing ball
  //   4 - walking aritchoke
  //   5  - grumpy pineapple
  //   6 - four-legged pinecone
  const submitFunc = async (id: number) => {
    if (id !== -1) {
      let dictionary = 
                        ["LazyAcorn", 
                        "ClimbingCactus", 
                        "BreathingBall", 
                        "WalkingAritchoke", 
                        "GrumpyPineapple", 
                        "FourLeggedPinecone"];
  
      await fetch("https://backend.pangolino.org/whichpango?pango=" + dictionary[id-1])
        .then(res => res.json())
        .then(data => {
          setStats({
            pangolins:[ 
              {
                name: "Lazy Acorn",
                num: Number(data["LazyAcorn"] || 0)
              },
              {
                name: "Climbing Cactus",
                num: Number(data["ClimbingCactus"] || 0)
              },
              {
                name: "Breathing Ball",
                num: Number(data["BreathingBall"] || 0)
              },
              {
                name: "Walking Aritchoke",
                num: Number(data["WalkingAritchoke"] || 0)
              },
              {
                name: "Grumpy Pineapple",
                num: Number(data["GrumpyPineapple"] || 0)
              },
              {
                name: "Four-legged Pinecone",
                num: Number(data["FourLeggedPinecone"] || 0)
              }
            ],
            total: Number(data["LazyAcorn"] || 0)  + Number(data["ClimbingCactus"] || 0) + Number(data["BreathingBall"] || 0) + Number(data["WalkingAritchoke"] || 0) + Number(data["GrumpyPineapple"] || 0) + Number(data["FourLeggedPinecone"] || 0)
          })
          setIsSubmitted(true);
      });
    }
  }

  return (
    <WhichPangolinContext.Provider 
        value={{
            top,
            submit,
            cards,
            selectedId,
            selectFunc,
            submitFunc,
            isSubmitted, 
            stats,
            share
        }}>
      {children}
    </WhichPangolinContext.Provider>
  );
};

export default WhichPangolinContext;
