import { TitleSection2 } from "../../components/generic/TitleSection2";
import { PangoCard } from "./components/PangoCard";
import {FacebookShareButton, TwitterShareButton} from "react-share"
import WhichPangolinContext, {
  WhichPangolinContextInterface,
} from "../../context/WhichPangolinContext";
import { useContext } from "react";
import "./WhichPango.css";
import { CustomButton } from "../../components/buttons/Buttons";
import { message } from 'antd';
import { Progress } from 'antd';
import { Helmet } from 'react-helmet-async';


export function WhichPango(): JSX.Element {

  const success = () => {
    message.success({
      icon: " ",
      content: 'Copied: ' + share.share_url,
      className: 'custom-class',
      style: {
        position: 'absolute',
        top: '0',
        right: '0',
        fontfamily: 'Inter',
        color: 'var(--moss)',
      },
    });
  };
  

  const { top, submit, cards, selectedId, submitFunc, selectFunc, isSubmitted, stats, share } = useContext(
    WhichPangolinContext
  ) as WhichPangolinContextInterface;

  function copyLink(){
    navigator.clipboard.writeText(share.share_url);
    success();
  }


  return (
    <div className="WhichPango-Container">

      {/* @ts-ignore */}
      <Helmet>
        <title>Fun Quiz (You = Pangolin) - Pangolino.org</title>
        <meta name="description" content="Take part in our anonymous quiz and select the pangolin that represents you the most!"></meta>
        <link rel="canonical" href="/quiz"></link>
      </Helmet>

      <TitleSection2 title={top.title} description={top.description} />
      <div className="WhichPango-Cards">
        {cards.map((card, index) => (
          <span onClick={() => {
                if (!isSubmitted){
                  if (card.id !== selectedId){
                    selectFunc(cards[index].id)
                  } else {
                    selectFunc(-1)
                  }
                }
              }
            }
          >
            <PangoCard
              key={index}
              id={card.id}
              title={card.title}
              img={card.img}
              facts={card.facts}
              selectedId={selectedId}
            />
          </span>
        ))}
      </div>
      {!isSubmitted ? (
      <div className="WhichPango-Container-BtnContainer" onClick={() => submitFunc(selectedId) }>
        <CustomButton type={""} text={submit} styleType="filled-inversed" />
      </div>
      ) : (
      <div className="WhichPango-Results">
        <h1>Results</h1>
        {stats.pangolins.map((pango, index) => (
          <div className="WhichPango-Results-Item">
                <Progress percent={(pango.num/stats.total)*100} strokeColor={"#37402c"} format={(percent) => percent?.toFixed(0) + "%"}></Progress>
                <p>{pango.name}</p>
          </div>
        ))}
      </div>
      )}
      <div className="WhichPango-Share">
        <h1>{share.share_title}</h1>
        <div>
          <span onClick={copyLink}>
            <img alt="Link Share Button" className="WhichPango-Share-Image" src={share.share_link_img}></img>
          </span>
          <FacebookShareButton 
            quote={share.share_text}    
            url={"https://pangolino.org/quiz"}
          >
            <img alt="Facebook Share Button" className="WhichPango-Share-Image" src={share.share_fb_img}></img>
          </FacebookShareButton>
          <TwitterShareButton 
            title={share.share_text}
            url={"https://pangolino.org/quiz"}
          >
            <img alt="Twitter Share Button" className="WhichPango-Share-Image" src={share.share_tw_img}></img>
          </TwitterShareButton>
        </div>
      </div>
    </div>
  );
}
