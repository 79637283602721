import './Testimonials.css';
import { Testimonials as TestimonialsInterface, Testimonial } from '../../../context/MainContext';
import { Carousel } from 'antd';
import arrowRightMoss from '../../../assets/geometry/arrowRightMoss.svg';
import arrowLeftMoss from '../../../assets/geometry/arrowLeftMoss.svg';

export const Testimonials: React.FC<TestimonialsInterface> = (props) => {
    return(
        <div className='MainBody-TestimonialsContainer'>
            <h1>{props.title}</h1>
            <Carousel 
                arrows={true}
                nextArrow={<img alt="Next Arrow" className="MainBody-TestimonialsContainer-Carousel-Arrow" src={arrowRightMoss}></img>}
                prevArrow={<img alt="Prev Arrow" className="MainBody-TestimonialsContainer-Carousel-Arrow" src={arrowLeftMoss}></img>}
                autoplay autoplaySpeed={10000} className='MainBody-TestimonialsContainer-Carousel'>
                {props.items.map((testimonial: Testimonial, index: number) => {
                    return(
                        <div className='MainBody-TestimonialsContainer-Item'>
                            <div>
                                <h1>“</h1>
                                <p>{testimonial.text}</p>
                            </div>
                            <h3>{testimonial.name}</h3>
                            <h4>{testimonial.country}</h4>
                        </div>
                    )
                })}
            </Carousel>
        </div>    
    )
}