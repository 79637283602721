import "./GenericLeftRightC.css";
import { CustomButton } from "../buttons/Buttons";

export interface LeftRightCItem {
  label: string;
  title: string;
  description: string;
  button_1_text: string;
  button_1_link: string;
  button_2_text: string;
  button_2_link: string;
  img: string;
  position_left: boolean;
  mobileTopTitle?: boolean;
}

export const GenericLeftRightC: React.FC<LeftRightCItem> = (props) => {
  if (props.position_left) {
    return (
      <>
        <div className="GenericLeftRight-Container">
          <div className="GenericLeftRight-Container-Left">
            <h5>{props.label}</h5>
            {!props.mobileTopTitle ? ( <h1>{props.title}</h1>) : (<></>)}
            <h1 className="GenericLeftRight-DesktopTitle">{props.title}</h1>
            <p>{props.description}</p>
            <div className="GenericLeftRightButtons">
              <div className="GenericLeftRightButtons-First">
                <CustomButton
                  text={props.button_1_text}
                  type={props.button_1_link.startsWith("http") ? "external_link" : "link"}
                  to={props.button_1_link}
                  styleType="filled-inversed"
                  mobileFullLength={true}

                />
              </div>
              <div className="GenericLeftRightButtons-Second">
                { props.button_2_text !== "" &&
                    <CustomButton
                    text={props.button_2_text}
                    type={props.button_1_link.startsWith("http") ? "external_link" : "link"}
                    to={props.button_2_link}
                    styleType="unfilled-inversed"  
                    mobileFullLength={true}

                    />
                }
              </div>
            </div>
          </div>
          <div className="GenericLeftRight-Container-Right">
            <h5 className="GenericLeftRight-MobileLabel">{props.label}</h5>
            {props.mobileTopTitle ? ( <h1 className="GenericLeftRight-MobileTitle">{props.title}</h1>) : (<></>)}
            <img src={props.img} alt="Pangolino" />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div id="leftImage" className="GenericLeftRight-Container">
        <div className="GenericLeftRight-Container-Right">
          {props.mobileTopTitle ? ( <h1 className="GenericLeftRight-MobileTitle">{props.title}</h1>) : (<></>)}
          <img src={props.img} alt="Pangolino" />
        </div>

        <div className="GenericLeftRight-Container-Left">
          <h5>{props.label}</h5>
          {!props.mobileTopTitle ? ( <h1 >{props.title}</h1>) : (<></>)}
          <h1 className="GenericLeftRight-DesktopTitle">{props.title}</h1>
          <p>{props.description}</p>
          <div className="GenericLeftRightButtons">
            <div className="GenericLeftRightButtons-First">
            <CustomButton
                  text={props.button_1_text}
                  type={props.button_1_link.startsWith("http") ? "external_link" : "link"}
                  to={props.button_1_link}
                  styleType="filled-inversed"
                  mobileFullLength={true}
                />
            </div>
            <div className="GenericLeftRightButtons-Second">
            { props.button_2_text !== "" &&
                    <CustomButton
                    text={props.button_2_text}
                    type={props.button_1_link.startsWith("http") ? "external_link" : "link"}
                    to={props.button_2_link}
                    styleType="unfilled-inversed"  
                    mobileFullLength={true}

                    />
                }
            </div>
          </div>
        </div>
      </div>
    );
  }
};
