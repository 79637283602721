import './Person.css';
import { Person as PersonInterface } from '../../../context/WhoWeAreContext'
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export interface PersonProps {
    link: string;
    person: PersonInterface;
}

export const Person: React.FC<PersonProps> = (props) => { 

    return(
        <div className="WhoWeAre-TeamContainer-Person">
            <div>
                <div><p> <ReactMarkdown remarkPlugins={[remarkGfm]}>{props.person.description}</ReactMarkdown></p></div>
                <img alt={"Team member: " + props.person.name}  src={props.link + props.person.img}/>
            </div>
            <h1>{props.person.name}</h1>
            <h5>{props.person.title}</h5>
        </div>
    )
    
}