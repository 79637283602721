import './Communications.css'
// import Spinner from '../../components/generic/Spinner'
import BlogContext, { BlogContextInterface, Item as PostItem } from '../../context/CommunicationsContext'
import { useContext, useEffect, useState } from 'react';
import { TitleSection2 } from '../../components/generic/TitleSection2';
import { BlogItem } from './components/blogItem';
import { Pagination } from '../../components/generic/Pagination';
import { MarkdownPage } from './components/MarkdownPage2';
import { Drawer } from 'antd';
import closeMoss from '../../assets/geometry/closeMoss.svg'
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { scroller } from 'react-scroll';


export function Blog(this: any) : JSX.Element {

  let history = useNavigate();

  const {
    top,
    noItemsFirstLine,
    noItemsSecondLine,
    blog_images_folder,
    markdown_folder,
    publications,
    media,
    blogs,

    pageCounter,
    itemsToShow,
    ITEMS_PER_PAGE,
    totalItems
  } = useContext(BlogContext) as BlogContextInterface;   
  
  const [allItems, setAllItems] = useState<PostItem[]>([]);
  const [RealItemsToShow, setItemsToShow] = useState<PostItem[]>(itemsToShow);
  let fuse = false;


  // 0 - all
  // 1 - publications
  // 2 - blogs
  // 3 - media
  const [currentType, setCurrentType] = useState(0);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [RealPageCounter, setPageCounter] = useState<number[]>(pageCounter);

  const [currentBlog, setCurrentBlog] = useState<string>("");
  const [currentPost, setCurrentPost] = useState<PostItem>();

  const { id } = useParams();

  function loadPage(page: number, items: PostItem[], moveUp: boolean) {
  
    const newItems = items.filter(
        (item, index) => index >= (page * ITEMS_PER_PAGE) - ITEMS_PER_PAGE && index < (page * ITEMS_PER_PAGE)
    );

    newItems.sort((a, b) => b.sortID - a.sortID);
  
    setCurrentPage(page);
    setItemsToShow(newItems);

    if (moveUp) {
      scroller.scrollTo('Blog-Body-TypeSelctionContainer', {
        duration: 200,
        delay: 0,
        smooth: 'easeInQuart',
      })
    }

  }

  useEffect(() => {

    function setAllPages(everything: PostItem[]){
      let temp: [number] = [-1];
      for ( let i = 0; i < everything.length ; i++ ) {
        if (i % ITEMS_PER_PAGE === 0) {
          temp.push(i / ITEMS_PER_PAGE + 1);
        }
      }
      temp.shift()
      setPageCounter(temp);
      
    }
    
    function setEverything() {

      let everything = [...publications.items, ...blogs.items, ...media.items];
      
      if (currentType === 1) {
        everything = [...publications.items];
      } else if (currentType === 2) {
        everything = [...blogs.items];
      } else if (currentType === 3) {
        everything = [...media.items];
      }

      everything = everything.sort( function(a, b) {
        return b.sortID - a.sortID;
      })

      setAllPages(everything);
      setAllItems(everything);
      loadPage(1, everything, false);

      let blog = blogs.items.find(item => item.link === id)
      if (blog !== undefined) {
        showDrawer(id || "", blog);
      } 
    }
    
    if (fuse === false) {
      setEverything();
    } 
  // eslint-disable-next-line
  }, [fuse, pageCounter, itemsToShow, currentType]);


  const Divider = () => {
    return (
        <svg width="100%" height="1px">
            <line x1="100%" y1="100%" x2="0%" y2="100%" stroke="rgba(0, 0, 0, 0.25)" strokeWidth="1"></line>
        </svg>
    )
  }

  const [drawerVisible, setDrawerVisible] = useState(false);

  const showDrawer = (blog: string, post: PostItem) => {
    setCurrentBlog(blog);
    setCurrentPost(post)
    setDrawerVisible(true);
    history("/communications/" + blog);
  };

  const closeDrwaer = () => {
   setDrawerVisible(false);
   setCurrentPost({
    title: "",
    description: "",
    date: "",
    link: "",
    external: false,
    sortID: 0,
    tag: "",
  });
  setCurrentBlog("");
  history("/communications");
  };

  return(
    <div className='Blog'>
      {/* @ts-ignore */}
      <Helmet>
          <title>Communications - Pangolino.org</title>
          <meta name="description" content='Check out our Pangolin-related blogs, publications, as well as media references to us!'></meta>
          <link rel="canonical" href="/communications"></link>
      </Helmet>

      <div className='Blog-Body'>
        <TitleSection2 
          title={top.title}
          description={top.description}
        ></TitleSection2>
        <div>
          <div className="Blog-Body-TypeSelctionContainer">
            <div className={currentType === 0 ? "Blog-Body-TypeSelctionContainer-Item-Selected" : "Blog-Body-TypeSelctionContainer-Item"} onClick={() => {
              setCurrentType(0);
              loadPage(1, allItems, false);
            }}>
              {"All (" + totalItems + ")"}
            </div>

            <div className={currentType === 1 ? "Blog-Body-TypeSelctionContainer-Item-Selected" : "Blog-Body-TypeSelctionContainer-Item"} onClick={() => {
              setCurrentType(1);
              loadPage(1, publications.items, false);
            }}>
              {publications.title + " (" + publications.items.length + ")"}
            </div>

            <div className={currentType === 2 ? "Blog-Body-TypeSelctionContainer-Item-Selected " : "Blog-Body-TypeSelctionContainer-Item"} onClick={() => {
              setCurrentType(2);
              loadPage(1, blogs.items, false);
            }}>
              {blogs.title + " (" + blogs.items.length + ")"}
            </div>

            <div className={currentType === 3 ? "Blog-Body-TypeSelctionContainer-Item-Selected" : "Blog-Body-TypeSelctionContainer-Item"} onClick={() => {
              setCurrentType(3);
              loadPage(1, media.items, false);
            }}>
              {media.title + " (" + media.items.length + ")"}
            </div>
          </div>
          {RealItemsToShow.length === 0 && 
          <div className='Blog-Body-NoItems'>
            <div className="Blog-Body-NoItems-FirstLine">{noItemsFirstLine}</div>
            <div className="Blog-Body-NoItems-SecondLine">{noItemsSecondLine}</div>
          </div>}
          {RealItemsToShow.length !== 0 && RealItemsToShow.map(
            (item, index) => {
              return(
                <>
                  <BlogItem 
                    type={''}
                    button_text={'Read More'} 
                    link_prefix={item.external === false ? markdown_folder : ""}
                    title={item.title}
                    description={item.description} 
                    link={item.link} 
                    date={item.date} 
                    tag={item.tag}
                    external={item.external} 
                    sortID={item.sortID}  
                    key={index}       
                    post={item}
                    open={showDrawer}     
                  />
                  { index !== RealItemsToShow.length - 1 && <Divider></Divider> }
                </>
              )
          })}
          {RealItemsToShow.length !== 0 &&
          <div className='Blog-Body-Pagination'>
            <Pagination 
              currentPage={currentPage}
              pagesArray={RealPageCounter} 
              items={allItems}
              loadPage={loadPage}
            />
          </div>
      }
        </div>
      </div>
      <div className='Blog-Drawer'>
        <Drawer onClose={() => {closeDrwaer()}} className="Blog-Drawer" bodyStyle={{backgroundColor: "var(--papyrus)"}} drawerStyle={{backgroundColor: "var(--papyrus)", width: "100vw", height: "100vh"}} headerStyle={{backgroundColor: "var(--papyrus)"}} visible={drawerVisible} title="" placement="bottom" width={"100vw"} height={"100vh"} closeIcon={<img alt="Close Button" src={closeMoss}></img>}>
          <MarkdownPage blog={currentBlog} imagesFolder={blog_images_folder} currentPost={currentPost}/>
        </Drawer>
      </div>
    </div>
  )
}