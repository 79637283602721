import { useContext } from 'react';
import { TitleSection2 } from "../../components/generic/TitleSection2";
import { AwarenessBody } from './components/awarenessBody';
import { GenericLeftRightC } from '../../components/generic/GenericLeftRightC';
import AwarenessContext, { AwarenessContextInterface } from '../../context/AwarenessContext';
import './awareness.css';
import { Helmet } from 'react-helmet-async';


export function Awareness() : JSX.Element{

  const { top, body, materials } = useContext(
    AwarenessContext
  ) as AwarenessContextInterface;

  return(
    <div className='Awareness-Body'>
      {/* @ts-ignore */}
      <Helmet>
          <title>Awareness-Raising - Pangolino.org</title>
          <meta name="description" content='Our goal is to raise awareness of the conservation status of pangolins and encourage people to contribute to their conservation.'></meta>
          <link rel="canonical" href="/awareness"></link>
      </Helmet>

      <TitleSection2 {...top}/>
      <AwarenessBody {...body}/>
      {materials.map((material, index) => (
        <GenericLeftRightC key={index} {...material}/>
      ))}
    </div>
  )
}
