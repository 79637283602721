import './PangoCard_.css'

export interface PangoCardProps{
    id: number;
    title: string;
    img: string;
    facts: string[];
    selectedId: number;
}

export const PangoCard: React.FC<PangoCardProps> = (props) => { 

    return(  
        <div className={(props.selectedId !== props.id) ? "PangoCard-Container" : "PangoCard-Container PangoCard-Container-SELECTED"}>  
            <h1>{props.title}</h1>
            <img src={props.img} alt={'Image "' + props.title + '"'} />
            <div>
                {props.facts.map((fact, index) => (
                    <li key={index}>{fact}</li>
                ))}
            </div>
        </div>
    )
}
