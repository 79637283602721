import './Main.css';
import { GenericLeftRightC } from '../../components/generic/GenericLeftRightC';
import { useContext } from 'react';
import MainContext, { MainContextInterface } from '../../context/MainContext';
import { Numbers } from './components/Numbers';
import { Testimonials } from './components/Testimonials';
import { Top } from './components/Top';
import { Helmet } from 'react-helmet-async';

export function Main(): JSX.Element{

    const {top, subtop, body, numbers, testimonials} = useContext(
        MainContext ) as MainContextInterface;

    return(
        <div className='MainBody'>
            {/* @ts-ignore */}
            <Helmet>
                <title>Pangolino.org - We Stand For Pangolin Conservation</title>
                <meta name="description" content="Pangolino is a global network of volunteers, scientists, and pangolin enthusiasts committed to bending the curve of pangolin decline."></meta>
                <link rel="canonical" href="/"></link>
            </Helmet>

            <Top {...top}/>
            <GenericLeftRightC {...subtop}/>
            <Numbers {...numbers}/>
            {body.map((body: any, index: number) => {
                return(
                    <GenericLeftRightC {...body} key={index}/>
                )
            })}
            <Testimonials {...testimonials}/>
        </div>
    )
}
