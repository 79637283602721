import {FC, createContext, useEffect } from 'react'

import data from '../content/pages/converter.json'

export interface ConverterContextInterface  {
  top: Top;
  body1: Body1;
  body2: Body2;
  converter: Converter;
  instructions: Instructions;
}

export interface Top {
    title: string;
    description: string;
}

export interface Body1 {
    title: string;
    description: string;
    link_word: string;
    link_url: string;
    link_postfix: string;
}

export interface Body2 {
    title: string;
    text: string;
    button: string;
}

export interface Converter {
    converter_title: string;
    results_title: string;
    results_text: string;
    results_rows: ResultsRows;
    results_columns: ResultsColumns;
}

export interface ResultsRows {
  bb: string;
  ss: string;
  wb: string;
}

export interface ResultsColumns {
    first: string;
    second: string;
    third: string;
}

export interface Instructions {
    instructions_title: string;
    instructions_array: string[];
}

const ConverterContext = createContext<ConverterContextInterface | null>(data);

export const ConverterProvider: FC<any> = ({ children }) => { 

  // const [top, setTop] = useState<Top>(
  //   {
  //     title: "Loading...",
  //     description: "Loading..."
  //   }
  // )
  // const [body1, setBody1] = useState<Body1>(
  //   {
  //     title: "Loading...",
  //     description: "Loading...",
  //     link_word: "",
  //     link_url: "",
  //     link_postfix: ""
  //   }
  // )
  // const [body2, setBody2] = useState<Body2>(
  //   {
  //     title: "Loading...",
  //     text: "Loading...",
  //     button: "Loading..."
  //   }
  // )
  // const [converter, setConverter] = useState<Converter>(
  //   {
  //     converter_title: "Loading...",
  //     results_title: "Loading...",
  //     results_text: "Loading...",
  //     results_rows: {
  //       bb: "Loading...",
  //       ss: "Loading...",
  //       wb: "Loading..."
  //     },
  //     results_columns: {
  //       first: "Loading...",
  //       second: "Loading...",
  //       third: "Loading..."
  //     }
  //   }
  // )
  // const [instructions, setInstructions] = useState<Instructions>(
  //   {
  //     instructions_title: "Loading...",
  //     instructions_array: ["Loading..."]
  //   }
  // )

  useEffect(() => {

    // const fetchData = async () => {
    //   const response = await fetch(
    //     'https://raw.githubusercontent.com/DFGPangolinoConservation/Pangolino-Content/main/pages/converter.json'
    //   )
    //   const data: ConverterContextInterface = await response.json()
  
    //   initialLoad(data)
    // }
  
    // // This function is called when the page is first loaded
    // function initialLoad(data: ConverterContextInterface){
    //   setTop(data.top)
    //   setBody1(data.body1)
    //   setBody2(data.body2)
    //   setConverter(data.converter)
    //   setInstructions(data.instructions)
    // }
    
    // fetchData()
  }, [])

 

  return (
    <ConverterContext.Provider
      value={data}
    >
      {children}
      
    </ConverterContext.Provider>
  )
}

export default ConverterContext
