import {FC, createContext, useEffect } from 'react'
import { LeftRightCItem } from '../components/generic/GenericLeftRightC'

import data from '../content/pages/shop.json'

export interface ShopContextInterface  {
  top: Top;
  products: LeftRightCItem[];
}

export interface Top {
  title: string;
  description: string;
}

const ShopContext = createContext<ShopContextInterface | null>(null);

export const ShopProvider: FC<any> = ({ children }) => { 

  // const [top, setTop] = useState<Top>(
  //   {
  //     title: "Loading...",
  //     description: "Loading..."

  //   }
  // )

  // const [products, setproducts] = useState<LeftRightCItem[]>([
  //   {
  //     label: "Loading...",
  //     title: "Loading...",
  //     description: "Loading...",
  //     button_1_text: "Loading...",
  //     button_1_link: "Loading...",
  //     button_2_text: "Loading...",
  //     button_2_link: "Loading...",
  //     img: "",
  //     position_left: true
  //   }
  // ])


  useEffect(() => {
    
    // const fetchData = async () => {
    //   const response = await fetch(
    //     'https://raw.githubusercontent.com/DFGPangolinoConservation/Pangolino-Content/main/pages/shop.json'
    //   )
    //   const data: ShopContextInterface = await response.json()
  
    //   initialLoad(data)
    // }
  
    // // This function is called when the page is first loaded
    // function initialLoad(data: ShopContextInterface){
    //   setTop(data.top)
    //   setproducts(data.products)
    // }

    // fetchData()
  }, [])

 

  return (
    <ShopContext.Provider
      value={data}
    >
      {children}
      
    </ShopContext.Provider>
  )
}

export default ShopContext
