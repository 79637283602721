import './ContactUs.css';
import ContactContext, { ContactContextInterface } from '../../context/ContactContext';
import { useContext, useState } from 'react';
import { TitleSection2 } from '../../components/generic/TitleSection2';
import { CustomButton } from '../../components/buttons/Buttons';
import { Helmet } from 'react-helmet-async';

export function ContactUs(): JSX.Element{

    const { top, form, sendMessage, formValues } = useContext(ContactContext) as ContactContextInterface;    

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    return(
        <div className="ContactUs-Body">
            {/* @ts-ignore */}
            <Helmet>
                <title>Contact Us - Pangolino.org</title>
                <meta name="description" content="Let's get in touch! Contact Pangolino community using this form."></meta>
                <link rel="canonical" href="/contact"></link>
            </Helmet>

            <TitleSection2 {...top}/>
            <form className="ContactUs-Body-Form" onSubmit={() => false}>
                <h1>{form.title}</h1>
                <label className="ContactUs-Body-Form-Label">{form.name.label}*</label>
                <div>
                    <div>
                        <input className="ContactUs-Body-Form-Field" id="fname" onChange={(e) => setFirstName(e.target.value)}></input>
                        <label className="ContactUs-Body-Form-MicroLabel" htmlFor="lname">{form.name.first}</label>
                    </div>
                    <div>
                        <input className="ContactUs-Body-Form-Field" id="lname" onChange={(e) => setLastName(e.target.value)}></input>
                        <label className="ContactUs-Body-Form-MicroLabel" htmlFor="lname">{form.name.last}</label>
                    </div>
                </div>
                <label className="ContactUs-Body-Form-Label" htmlFor="email">{form.email}*</label>
                <input className="ContactUs-Body-Form-Field" id="email" onChange={(e) => setEmail(e.target.value)}></input>
                <label className="ContactUs-Body-Form-Label" htmlFor="subject">{form.subject}*</label>
                <input className="ContactUs-Body-Form-Field" id="subject" onChange={(e) => setSubject(e.target.value)}></input>
                <label className="ContactUs-Body-Form-Label" htmlFor="message">{form.message}*</label>
                <textarea className="ContactUs-Body-Form-LargeField" id="message" onChange={(e) => setMessage(e.target.value)}></textarea>
                <span>
                    <span  onClick={() => !formValues.sent ? sendMessage(firstName, lastName, email, subject, message) : ""}>
                        <CustomButton 
                            type={'default'}
                            styleType={'filled-inversed'} 
                            text={'Send'}>
                        </CustomButton>
                    </span>
                    <span className={formValues.sent ? "ContactUs-Body-Form-SuccessMessage" : "ContactUs-Body-Form-ErrorMessage"}>
                        {formValues.description}
                    </span>
                </span>
            </form>
        </div>
    )
}