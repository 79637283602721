import './Instructions.css';

interface InstructionsProps {
    title: string;
    instructions: string[];
}

export const Instructions: React.FC<InstructionsProps> = (props) => {
    return(
        <div className="Instructions-Container">
            <h1>{props.title}</h1>
            <ol>
            {props.instructions.map((string, index) =>
                {
                    return(
                        <li>{string}</li>
                    )
                }
            )}
            </ol>
        </div>
    )
}