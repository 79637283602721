import React from 'react'
import { CustomButton } from '../../../components/buttons/Buttons';
import './artTitle.css'

interface ArtTitleProps {
    title: string;
    description: string;
    button: string;
    button_link: string;
}

export const ArtTitle: React.FC<ArtTitleProps> = (props) => {

    return(
        <div className='ArtGallery-Title-body'>
            <div className='ArtGallery-Title-body-flex'>
                <h1 className='ArtGallery-Title-body-flex-title'>
                    {props.title}
                </h1>
                <p className='ArtGallery-Title-body-flex-description'>
                    {props.description}
                </p>
                <a className="ArtGallery-Title-body-flex-button" href={props.button_link}  target="_blank" rel="noopener noreferrer">
                    <CustomButton 
                        type={'default'} 
                        text={props.button}>
                    </CustomButton>
                </a>
            </div>
        </div>
    )
}
