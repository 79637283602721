import { useContext } from 'react';
import { TitleSection2 } from "../../components/generic/TitleSection2";
import { GenericLeftRightC } from '../../components/generic/GenericLeftRightC';
 import ShopContext, { ShopContextInterface } from '../../context/ShopContext';
import './Shop.css';
import { Helmet } from 'react-helmet-async';

export function Shop() : JSX.Element{

  const { top, products } = useContext(
    ShopContext
  ) as ShopContextInterface;

  return(
    <div className='Shop-Body'>
      {/* @ts-ignore */}
      <Helmet>
          <title>Shop - Pangolino.org</title>
          <meta name="description" content="Support our upcoming community conservation project and pangolins by purchasing our goods."></meta>
          <link rel="canonical" href="/shop"></link>
      </Helmet>

      <TitleSection2 {...top}/>
      <div>
        {products.map((product, index) => (
          <GenericLeftRightC key={index} {...product}/>
        ))}
      </div>
    </div>
  )
}
