import {FC, createContext, useEffect } from 'react'

import data from '../content/pages/community_con.json'

export interface CommunityConContextInterface  {
  top: Top,
  body: Body
}

interface Top {
  title: string;
  description: string;
}

interface Body {
  title: string;
  description: string;
}

const CommunityConContext= createContext<CommunityConContextInterface | null>(data);

export const CommunityConProvider: FC<any> = ({ children }) => { 

  // const [top, setTop] = useState<Top>({
  //   title: "Loading...",
  //   description: ""
  // });

  // const [body, setBody] = useState<Body>({
  //   title: "",
  //   description: "Loading..."
  // });

  useEffect(() => {
    
    // const fetchData = async () => {
    //   const response = await fetch(
    //     'https://raw.githubusercontent.com/DFGPangolinoConservation/Pangolino-Content/main/pages/community_con.json'
    //   )
    //   const data: CommunityConContextInterface = await response.json()
  
    //   initialLoad(data)
    // }
  
    // // This function is called when the page is first loaded
    // function initialLoad(data: CommunityConContextInterface){
    //   setTop(data.top)
    //   setBody(data.body)
    //   console.log(data)
    // }

    // fetchData()
  }, [])

 

  return (
    <CommunityConContext.Provider
      value={data}
    >
      {children}
      
    </CommunityConContext.Provider>
  )
}

export default CommunityConContext
