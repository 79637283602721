import React from 'react'
import './TitleSectionC.css'

interface TitleSectionCProps {
    title: string;
    description: string;
}

export const TitleSectionC: React.FC<TitleSectionCProps> = (props) => {

    return(
        <div className='TitleSectionC-body'>
            <div className='TitleSectionC-body-flex'>
                <h1 className='TitleSectionC-body-flex-title'>
                    {props.title}
                </h1>
                <p className='TitleSectionC-body-flex-description'>
                    {props.description}
                </p>
            </div>
        </div>
    )
}
