import { CustomButton } from '../../../components/buttons/Buttons';
import Spinner from '../../../components/generic/Spinner';
import './ScaleConverter.css';
import { useState } from 'react';

interface ScaleConverterProps {
    title1: string;
    title2: string;
    description: string;
    firstColumn: string;
    secondColumn: string;
    thirdColumn: string;
    firstRow: string;
    secondRow: string;
    thirdRow: string;
}

const Divider = () => {
    return (
        <svg width="2" height="64" viewBox="0 0 2 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line opacity="0.4" x1="1" y1="2.18557e-08" x2="0.999997" y2="64" stroke="#37402C"/>
        </svg>
    )
}

const InvisibleDivider = () => {
    return (
        <svg width="2" height="64" viewBox="0 0 2 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line opacity="0.4" x1="1" y1="2.18557e-08" x2="0.999997" y2="64" stroke="#00000000"/>
        </svg>
    )
}

export const ScaleConverter: React.FC<ScaleConverterProps> = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    const [isCalculated, setIsCalculated] = useState(false)
    const [isKg, setIsKg] = useState(false);
    const [inputValue, setInputValue] = useState<number>(0);


    //Timer for animation
    const [bb, setBB] = useState<string[]>(["?", "?", "?"]);
    const [sp, setSP] = useState<string[]>(["?", "?", "?"]);
    const [wb, setWB] = useState<string[]>(["?", "?", "?"]);

    function numberWithCommas(x: number) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    
    function changeInputType(type: string){
        if (type === "g"){
            if (isKg){
                setInputValue(inputValue*1000);
                setIsKg(false);
            }
        } else if (type === "kg"){ 
            if (!isKg){
                setInputValue(inputValue/1000);
                setIsKg(true);
            }
        }
    }

    function calculate(input: number){
        if (isKg){
            input = input * 1000;
        }
        setIsLoading(true)
        fetch('https://backend.pangolino.org/converter?input=' + input)
            .then(response => response.json())
            .then(response => {
                const data = JSON.parse(JSON.stringify(response));

                var temp = [numberWithCommas(data.output[0]["Abundance.lower.95..CI"]), numberWithCommas(data.output[0]["Abundance.total.estimate"]), numberWithCommas(data.output[0]["Abundance.upper.95..CI"])];
                setBB(temp);
                var temp2 = [numberWithCommas(data.output[0]["Abundance.lower.95..CI.1"]), numberWithCommas(data.output[0]["Abundance.total.estimate.1"]), numberWithCommas(data.output[0]["Abundance.upper.95..CI.1"])];
                setSP(temp2);
                var temp3 = [numberWithCommas(data.output[0]["Abundance.lower.95..CI.2"]), numberWithCommas(data.output[0]["Abundance.total.estimate.2"]), numberWithCommas(data.output[0]["Abundance.upper.95..CI.2"])];
                setWB(temp3);
                setIsLoading(false)
                setIsCalculated(true)
            })
    }

    return(
        <>
            <div className="ScaleConverter-Container">
                <h1>{props.title1}</h1>
                <div className="ScaleConverter-Calculator-Desktop">
                    <h5>Enter the mass of seized pangolin scales:</h5>
                    <div className='ScaleConverter-Calculator-Desktop-Inputs'>
                        <input 
                            value={inputValue.toString()}
                            id="inputValue"
                            className="ScaleConverter-Calculator-Desktop-ValueInput" 
                            type={"number"}
                            pattern="[0-9.]+"
                            onChange={(e) => { 
                                setInputValue(parseInt(e.target.value))}}>
                        </input>
                        <div className='ScaleConverter-Calculator-Desktop-ValueInput-Measure'>
                            <button 
                                className={ isKg ? 'ScaleConverter-Calculator-Desktop-Inputs-kgButton' : 'ScaleConverter-Calculator-Desktop-Inputs-gButton' }
                                onClick={() => changeInputType("kg")}
                            >
                            kg
                            </button>
                            <button 
                                className={ isKg ? 'ScaleConverter-Calculator-Desktop-Inputs-gButton' : 'ScaleConverter-Calculator-Desktop-Inputs-kgButton' }
                                onClick={() => changeInputType("g")}
                            >g
                            </button>
                        </div>
                    </div>
                    <div className='ScaleConverter-Calculator-Desktop-Buttons'>
                        <div onClick={() => calculate(inputValue)}>
                            <CustomButton 
                                type={''} 
                                styleType={'filled-inversed'}
                                text={'Calculate'}>
                            </CustomButton>
                        </div>
                        <div onClick={() => {setInputValue(0)}}>
                            <CustomButton 
                                type={''} 
                                styleType={'unfilled-inversed'}
                                text={'Clear'}>
                            </CustomButton>
                        </div>
                    </div>
                </div>
               
            </div>
            {isLoading ? <Spinner></Spinner> : isCalculated ?
                <div className="ScaleConverter-Container-Second">
                    <div className={isCalculated ? 'mountedStyle' : 'unmountedStyle'}>
                        <h1>{props.title2}</h1>
                        {props.description !== "" &&
                            <p>{props.description}</p>
                        }
                        <table className="ScaleConverter-Results">
                            <tr>  
                                <th className='ScaleConverter-Results-RowHeaders'>
                                    {/*empty*/}
                                </th > 
                                <th className='ScaleConverter-Results-ColumnHeaders'>
                                   {props.firstColumn}
                                </th>
                                <InvisibleDivider/>
                                <th className='ScaleConverter-Results-ColumnHeaders'>
                                    {props.secondColumn}
                                </th>
                                <InvisibleDivider/>
                                <th className='ScaleConverter-Results-ColumnHeaders'>
                                    {props.thirdColumn}
                                </th>
                            </tr>
                            <tr> 
                                <th className='ScaleConverter-Results-RowHeaders'>
                                    {props.firstRow}
                                </th> 
                                <td>
                                    {bb[1]}
                                </td>
                                <Divider/>
                                <td>
                                    {bb[0]}
                                </td>
                                <Divider/>
                                <td>
                                    {bb[2]}
                                </td>
                            </tr>  
                            <tr>  
                                <th className='ScaleConverter-Results-RowHeaders'>
                                    {props.secondRow}
                                </th>
                                <td>
                                    {sp[1]}
                                </td>
                                <Divider/>
                                <td>
                                    {sp[0]}
                                </td>
                                <Divider/>
                                <td>
                                    {sp[2]}
                                </td>
                            </tr>
                            <tr>  
                                <th className='ScaleConverter-Results-RowHeaders'>
                                    {props.thirdRow}
                                </th>
                                <td>
                                    {wb[1]}
                                </td>
                                <Divider/>
                                <td>
                                    {wb[0]}
                                </td>
                                <Divider/>
                                <td>
                                    {wb[2]}
                                </td>
                            </tr>  
                        </table>
                    </div> 
                </div> : <></>
                }    
            
            <div>
                
            </div>
        </>
    )
}