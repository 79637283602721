import { CustomButton } from "../buttons/Buttons";
import threeDots from "../../assets/geometry/threeDots.svg";
import './Pagination.css';

export interface PaginationProps {
    currentPage: number;
    pagesArray: number[];
    items: any[];
    loadPage: (page: number, items: any[], moveUp: boolean) => void;
}

export function Pagination({currentPage, pagesArray, loadPage, items} : PaginationProps): JSX.Element {
    
  let dotsPut = false;

  return (
    <div className= "Generic-Pagination-Container">
      <span
        onClick={() => {
          if (currentPage !== 1) loadPage(currentPage - 1, items, true);
        }}
      >
        <CustomButton
          type={"Default"}
          styleType={"Left-Arrow"}
          text={""}
        ></CustomButton>
      </span>
      {pagesArray.map((page, index) => {
        if (
          index !== 0 &&
          index !== pagesArray.length - 1 &&
          !(index >= currentPage - 3 && index <= currentPage + 1)
        ) {
          if (!dotsPut) {
            dotsPut = true;
            return <img alt="..." src={threeDots}></img>;
          } else {
            return null;
          }
        } else {
          dotsPut = false;
          return (
            <span
              onClick={() => {
                loadPage(page, items, true);
              }}
            >
              <CustomButton
                number={page}
                selectedButton={currentPage}
                type={"Default"}
                styleType={"Line"}
                text={page.toString()}
              ></CustomButton>
            </span>
          );
        }
      })}
      <span
        onClick={() => {
          if (currentPage !== pagesArray.length) loadPage(currentPage + 1, items, true);
        }}
      >
        <CustomButton
          type={"Default"}
          styleType={"Right-Arrow"}
          text={""}
        ></CustomButton>
      </span>
    </div>
  );
}
