import './PangolinFactsCarousel.css';
import { Facts } from "../../../context/PangolinFactsContext";
import { useEffect, useRef } from 'react';
import { Carousel } from 'antd';
import ReactDOM from 'react-dom';
import arrowRightMoss from '../../../assets/geometry/arrowRightMoss.svg';
import arrowLeftMoss from '../../../assets/geometry/arrowLeftMoss.svg';
export const PangolinFactsCarousel: React.FC<Facts> = (props) => { 

  const containerRef = useRef(null);

    const carousel = (
        <Carousel 
        arrows={true}
        nextArrow={<img alt="Next Arrow" className="PangolinsFactsCarousel-Carousel-Arrow" src={arrowRightMoss}></img>}
        prevArrow={<img alt="Prev Arrow" className="PangolinsFactsCarousel-Carousel-Arrow" src={arrowLeftMoss}></img>}
            autoplay autoplaySpeed={20000} className='PangolinsFactsCarousel-Body-Carousel'>
            {props.images.map((image: any, index: number) => {
                return (
                <div className='PangolinsFactsCarousel-Body-Carousel-Item'>
                    <div>
                      <img src={props.images_basis + image} alt={`Fact ${index}`}></img>
                    </div>
                    <span>
                      <p>
                        {props.facts[index]}
                      </p>
                    </span>
                </div>)
            })}
        </Carousel>
      );
    
    useEffect (() => {
        ReactDOM.render(carousel, containerRef.current);
    })

  
  return (
    <div className="PangolinsFactsCarousel-Body">
      <h1>{props.title}</h1>
      <p>{props.description}</p>
      <div ref={containerRef}/>
    </div>
  );
}
