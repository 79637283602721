import './blogItem.css'
import { Item } from '../../../context/CommunicationsContext'
import { CustomButton } from '../../../components/buttons/Buttons';

export interface BlogItemInterface extends Item {
    type: string;
    button_text: string;
    link_prefix: string;
    post: Item;
    open: (blog: string, post: Item) => void;
}


export const BlogItem: React.FC<BlogItemInterface> = (props) => { 

    return(
        <div className="Blog-Container">
            <h5 className='Blog-Container-Date'>
                {props.date}
            </h5>
            <h1 className='Blog-Container-Title'>
                {props.title}
            </h1>
            <div className='Blog-Container-TypeContainer'>
                <h4 className='Blog-Container-Type'>
                    {props.tag}
                </h4>
            </div>
            <p className='Blog-Container-Description'>
                {props.description}
            </p>
            <span onClick={() => {props.external === false ? props.open(props.link, props.post) : void(0)}}>
                <CustomButton 
                    type={props.external === true  ? 'external_link' : 'default'}
                    to={props.link} 
                    styleType={"filled-inversed-arrow"}
                    text={props.button_text}     
                />
            </span>
        </div>
    )
}