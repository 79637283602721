import './Careers.css'
// import Spinner from '../../components/generic/Spinner'
import CareerContext, { CareerContextInterface, Item as CareerItem } from '../../context/CareersContext'
import { useContext, useEffect, useState } from 'react';
import { TitleSection2 } from '../../components/generic/TitleSection2';
import { CareerEntry } from './components/careerItem';
import { Pagination } from '../../components/generic/Pagination';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { scroller } from 'react-scroll';


export function Careers(this: any) : JSX.Element {

  let history = useNavigate();

  const {
    top,
    noItemsFirstLine,
    noItemsSecondLine,
    current,
    closed,
    pageCounter,
    itemsToShow,
    ITEMS_PER_PAGE,
    totalItems
  } = useContext(CareerContext) as CareerContextInterface;   
  
  const [allItems, setAllItems] = useState<CareerItem[]>([]);
  const [RealItemsToShow, setItemsToShow] = useState<CareerItem[]>(itemsToShow);
  let fuse = false;


  // 0 - all
  // 1 - publications
  // 2 - blogs
  // 3 - media
  const [currentType, setCurrentType] = useState(1);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [RealPageCounter, setPageCounter] = useState<number[]>(pageCounter);


  function loadPage(page: number, items: CareerItem[], moveUp: boolean) {
  
    const newItems = items.filter(
        (item, index) => index >= (page * ITEMS_PER_PAGE) - ITEMS_PER_PAGE && index < (page * ITEMS_PER_PAGE)
    );

    newItems.sort((a, b) => b.sortID - a.sortID);
  
    setCurrentPage(page);
    setItemsToShow(newItems);

    if (moveUp) {
      scroller.scrollTo('Blog-Body-TypeSelctionContainer', {
        duration: 200,
        delay: 0,
        smooth: 'easeInQuart',
      })
    }

  }

  useEffect(() => {

    function setAllPages(everything: CareerItem[]){
      let temp: [number] = [-1];
      for ( let i = 0; i < everything.length ; i++ ) {
        if (i % ITEMS_PER_PAGE === 0) {
          temp.push(i / ITEMS_PER_PAGE + 1);
        }
      }
      temp.shift()
      setPageCounter(temp);
      
    } 
    
    function setEverything() {

      let everything = [...current.items];
      
      if (currentType === 0) {
        everything = [...current.items, ...closed.items]
      } 
      else if (currentType === 1) {
        everything = [...current.items]
      }
      else if (currentType === 2) {
        everything = [...closed.items]
      }
      // } else if (currentType === 2) {
      //   everything = [...blogs.items];
      // } else if (currentType === 3) {
      //   everything = [...media.items];
      // }

      everything = everything.sort( function(a, b) {
        return b.sortID - a.sortID;
      })

      setAllPages(everything);
      setAllItems(everything);
      loadPage(1, everything, false);

      
    }
    
    if (fuse === false) {
      setEverything();
    } 
  // eslint-disable-next-line
  }, [fuse, pageCounter, itemsToShow, currentType]);


  const Divider = () => {
    return (
        <svg width="100%" height="0px">
            <line x1="100%" y1="100%" x2="0%" y2="100%" stroke="rgba(0, 0, 0, 0.25)" strokeWidth="1"></line>
        </svg>
    )
  }



  return(
    <div className='Blog'>
      {/* @ts-ignore */}
      <Helmet>
          <title>Careers - Pangolino.org</title>
          <meta name="description" content='We are hiring! Join the Pangolino team!'></meta>
          <link rel="canonical" href="/communications"></link>
      </Helmet>

      <div className='Blog-Body'>
        <TitleSection2 
          title={top.title}
          description={top.description}
        ></TitleSection2>
        <div>
          <div className="Blog-Body-TypeSelctionContainer">
            <div className={currentType === 0 ? "Blog-Body-TypeSelctionContainer-Item-Selected" : "Blog-Body-TypeSelctionContainer-Item"} onClick={() => {
              setCurrentType(0);
              loadPage(1, allItems, false);
            }}>
              {"All (" + totalItems + ")"}
            </div>

            <div className={currentType === 1 ? "Blog-Body-TypeSelctionContainer-Item-Selected" : "Blog-Body-TypeSelctionContainer-Item"} onClick={() => {
              setCurrentType(1);
              loadPage(1, current.items, false);
            }}>
              {current.title + " (" + current.items.length + ")"}
            </div>

            <div className={currentType === 2 ? "Blog-Body-TypeSelctionContainer-Item-Selected" : "Blog-Body-TypeSelctionContainer-Item"} onClick={() => {
              setCurrentType(2);
              loadPage(1, closed.items, false);
            }}>
              {closed.title + " (" + closed.items.length + ")"}
            </div>

            {/* <div className={currentType === 2 ? "Blog-Body-TypeSelctionContainer-Item-Selected " : "Blog-Body-TypeSelctionContainer-Item"} onClick={() => {
              setCurrentType(2);
              loadPage(1, blogs.items, false);
            }}>
              {blogs.title + " (" + blogs.items.length + ")"}
            </div>

            <div className={currentType === 3 ? "Blog-Body-TypeSelctionContainer-Item-Selected" : "Blog-Body-TypeSelctionContainer-Item"} onClick={() => {
              setCurrentType(3);
              loadPage(1, media.items, false);
            }}>
              {media.title + " (" + media.items.length + ")"}
            </div> */}
          </div>
          {RealItemsToShow.length === 0 && 
          <div className='Blog-Body-NoItems'>
            <div className="Blog-Body-NoItems-FirstLine">{noItemsFirstLine}</div>
            <div className="Blog-Body-NoItems-SecondLine">{noItemsSecondLine}</div>
          </div>}
          {RealItemsToShow.length !== 0 && RealItemsToShow.map(
            (item, index) => {
              return(
                <>
                  <CareerEntry
                    type={''}
                    button_text={'Learn More & Apply'}
                    title={item.title}
                    description={item.description}
                    link={item.link}
                    tag={item.tag}
                    sortID={item.sortID} 
                    date={item.date}                 
                  />
                  { index !== RealItemsToShow.length - 1 && <Divider></Divider> }
                </>
              )
          })}
          {RealItemsToShow.length !== 0 &&
          <div className='Blog-Body-Pagination'>
            <Pagination 
              currentPage={currentPage}
              pagesArray={RealPageCounter} 
              items={allItems}
              loadPage={loadPage}
            />
          </div>
      }
        </div>
      </div>
    </div>
  )
}