
import 'bootstrap/dist/css/bootstrap.min.css'
import { Art, Image } from './components/artImage';
import { ArtTitle } from './components/artTitle';
import { useEffect, useState } from 'react';
import './artGallery.css'
import { CustomButton } from '../../components/buttons/Buttons';
import threeDots from '../../assets/geometry/threeDots.svg';
import { scroller } from 'react-scroll'
import { Helmet } from 'react-helmet-async';

import data from '../../content/pages/art_gallery.json';

//import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

export interface ArtInterface {
    "illustrator": string,
    "origin": string,
    "art_description": string,
    "src": string
}


export function ArtGallery(): JSX.Element{

    const ARTS_PER_PAGE = 9;

    const titleSection_Text: string = data.main_title
    const titleSection_Description: string = data.main_description
    const titleSection_button: string = data.button_text
    const button_link: string = data.button_link

    const [gallery, setGallery] = useState<[JSX.Element]>([<div></div>]);
    const allArts: ArtInterface[] = data.arts
    const [pageCounter, setPageCounter] = useState<number[]>([]);

    const [fuse, setFuse] = useState<Boolean>(false);

    const [currentPage, setCurrentPage] = useState<number>(1);

    let dotsPut = false;


    function loadArts(artsArray: ArtInterface[]){
        let temp: [JSX.Element] = [<div></div>];
        let counter = 0;
        for (let art in artsArray) {
            let i = Art({ author: artsArray[art].illustrator, text: artsArray[art].art_description, country: artsArray[art].origin, photo: "/images/artImages/" + artsArray[art].src });
            if (i !== null && counter < ARTS_PER_PAGE) {
                temp.push(i);
                counter++;
            }
        }
        setGallery(temp);
    }

    function loadPageNumber(totalPages: number){
        const len = totalPages;
        let counter = 0;
        let temp = [];
        for (let i = 0; i < len; i = i + ARTS_PER_PAGE) {
            counter++;
            temp.push(counter);
        }            
        setPageCounter(temp);
    }

    function loadMore(page: number){
    
        const newArts = allArts.filter(
            (art, index) => index >= (page * ARTS_PER_PAGE) - ARTS_PER_PAGE && index < (page * ARTS_PER_PAGE)
        );
        
        loadArts(newArts);

        setCurrentPage(page);

        scroller.scrollTo('Art-Container', {
            duration: 200,
            delay: 0,
            smooth: 'easeInQuart',
          })
    }
    

    useEffect(() => {

        const allArts: ArtInterface[] = data.arts


        loadArts(allArts);
        loadPageNumber(allArts.length);
        setFuse(true);

        // const fetchData: any = async () => {
        //     await fetch('https://raw.githubusercontent.com/DFGPangolinoConservation/Pangolino-Content/main/pages/art_gallery.json')
        //         .then(response => response.json())
        //         .then(response => {
        //             console.log("Fetching...")
        //             setTitleSection_Text(response.main_title);
        //             setTitleSection_Description(response.main_description);
        //             setTitleSection_Button(response.button_text);
        //             setButton_Link(response.button_link);
    
    
        //             setLink(response.art_image_basis);
                    
    
        //             setAllArts(response.arts)
    
        //             loadArts(response.arts, response.art_image_basis);
        //             loadPageNumber(response.arts.length);
        //             setFuse(true);
        //         })
        //         .catch(error => {
        //             console.log(error);
        //         })    
        // }

        // if (fuse === false) {
        //     fetchData();
        // }
        
    }, [fuse]);

    return(
        <div className="Art-Gallery-Container" >
            {/* @ts-ignore */}
            <Helmet>
                <title>Art Gallery - Pangolino.org</title>
                <meta name="description" content='Art meets science! Explore pangolins and their conservation through the creative eyes and minds of volunteer artists from all over the world.'></meta>
                <link rel="canonical" href="/gallery"></link>
            </Helmet>
    
            <ArtTitle
                title={titleSection_Text}
                description={titleSection_Description}
                button={titleSection_button}
                button_link={button_link}
            />

            <Image
                gallery={gallery}
            />

        {
            
        }
        <div className= "Art-Gallery-Buttons-Container">
            <span onClick={() => {if (currentPage !== 1) loadMore(currentPage - 1);}}>
                <CustomButton
                    type={'Default'} 
                    styleType={'Left-Arrow'} 
                    text={""}
                    >
                </CustomButton>
            </span>
            {pageCounter.map(
                (page, index) => {
                  
                    if (index !== 0 && index !== pageCounter.length - 1 
                        && !(index >= currentPage - 3 && index <= currentPage + 1)){
                            if (!dotsPut) {
                                dotsPut = true
                                return <img alt="..." src={threeDots}></img>
                            } else {
                                return null;
                            }
                    } else {
                        dotsPut = false;
                        return(
                            <span onClick={() => {loadMore(page);}}>
                                <CustomButton
                                    number={page}
                                    selectedButton={currentPage}
                                    type={'Default'} 
                                    styleType={'Line'} 
                                    text={page.toString()}
                                    >
                                </CustomButton>
                            </span>
                        )
                    }
                }
             )}
            <span onClick={() => { if (currentPage !== pageCounter.length) loadMore(currentPage + 1);}}>
                <CustomButton
                    type={'Default'} 
                    styleType={'Right-Arrow'} 
                    text={""}
                    >
                </CustomButton>
            </span>
        </div>

        </div>    
    )
}