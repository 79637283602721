import {  useRef} from 'react';

//import 'bootstrap/dist/css/bootstrap.min.css'

import './artImage.css'
import { Image as ImageAnt }  from 'antd';


// import InfiniteScroll from 'react-infinite-scroller';

export interface ArtProps{
    author: string;
    photo?: any;
    text: string;
    country: string;
}

export const Art: React.FC<ArtProps> = (props) => { 
    return(  
        <div>  
            <ImageAnt 
                src={props.photo} 
            /> 
            <div>
                <div className='Art-Container-Card-Author'>{props.author}</div>
                <div className='Art-Container-Card-Text'> {props.text} </div>
                <div className='Art-Container-Card-Text'> {props.country} </div>
            </div>
        </div>
    )
}
export interface CardProps{
    gallery: [JSX.Element]
}


export const Image: React.FC<CardProps> = (props) => { 


    const containerRef = useRef(null);
    
    return(
        <div className="Art-Container">
            
            <div className='Art-Container-Card'>
                {props.gallery.map((art: any, index: number) => {
                    if (index !== 0){
                        return(art)
                    } else {
                        return(<></>)
                    }
                })} 
                </div>
                <div ref={containerRef} className='Team-Container-People-Mobile'>
            </div>
        </div>
    )
}