import './careerItem.css'
import { Item } from '../../../context/CareersContext'
import { CustomButton } from '../../../components/buttons/Buttons';

export interface CareerEntryInterface extends Item {
    type: string;
    button_text: string;
}


export const CareerEntry: React.FC<CareerEntryInterface> = (props) => { 

    return(
        <div className="Career-Container">
            <div className="Career-Container-Title">{props.title}</div>
            <div className="Career-Container-Description">{props.description}</div>
            <div className="Career-Container-Button">
            <CustomButton 
                type={'external_link'}
                to={props.link} 
                styleType={"filled-inversed-arrow"}
                text={props.button_text}     
            />
            </div>

        </div>
    )
}