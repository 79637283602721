import { TitleSection2 } from "../../components/generic/TitleSection2";
import { PangolinFactsMain } from "./components/PangolinFactsMain";
import { PangolinFactsCarousel } from "./components/PangolinFactsCarousel";
import { useContext } from "react";
import PangolinFactsContext, {
  PangolinFactsContextInterface,
} from "../../context/PangolinFactsContext";
import './PangolinFacts.css';
import { Helmet } from 'react-helmet-async';


export function PangolinFacts(): JSX.Element {
  const { top, body, facts } = useContext(
    PangolinFactsContext
  ) as PangolinFactsContextInterface;

  return (
    <div className="PangolinFacts-Body">
      {/* @ts-ignore */}
      <Helmet>
          <title>Pangolin Facts - Pangolino.org</title>
          <meta name="description" content="Learn fact about pangolins - unique scaly mammals found only in Africa and Asia.  "></meta>
          <link rel="canonical" href="/facts"></link>
      </Helmet>

      <TitleSection2 {...top}/>
      <PangolinFactsMain {...body}/>
      <PangolinFactsCarousel {...facts}/>
    </div>
  );
}
