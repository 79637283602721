import { FC, createContext, useEffect } from "react";

import data from '../content/pages/who_we_are.json'

export interface WhoWeAreContextInterface {
  top: Top;
  team: Team;
  board_of_trustees: Team;
  funders: Funders;
}

export interface Top {
  title: string;
  description: string;
}

export interface Person {
  name: string;
  title: string;
  description: string;
  img: string;
}

export interface Team {
  title: string;
  image_link: string;
  people: Person[];
}

export interface Funders {
  title: string;
  images_link: string;
  images: string[];
}

const WhoWeAreContext = createContext<WhoWeAreContextInterface | null>(data);

export const WhoWeAreProvider: FC<any> = ({ children }) => {
  // const [top, setTop] = useState<Top>({
  //   title: "Loading...",
  //   description: "Loading...",
  // });
  // const [team, setTeam] = useState<Team>({
  //   title: "Loading...",
  //   image_link: "",
  //   people: [],
  // });
  // const [funders, setFunders] = useState<Funders>({
  //   title: "Loading...",
  //   images_link: "",
  //   images: [],
  // });

  // const [board_of_trustees, setBoardOfTrustees] = useState<Team>({
  //   title: "Loading...",
  //   image_link: "",
  //   people: [],
  // });

  useEffect(() => {
    
    // const fetchData = async () => {
    //   const response = await fetch(
    //     "https://raw.githubusercontent.com/DFGPangolinoConservation/Pangolino-Content/main/pages/who_we_are.json"
    //   );
    //   const data: WhoWeAreContextInterface = await response.json();
  
    //   initialLoad(data);
    // };
  
    // // This function is called when the page is first loaded
    // function initialLoad(data: WhoWeAreContextInterface) {
    //   setTop(data.top);
    //   setTeam(data.team)
    //   setBoardOfTrustees(data.board_of_trustees)
    //   setFunders(data.funders);
    // }

    // fetchData();
  }, []);


  return (
    <WhoWeAreContext.Provider
      value={data}
    >
      {children}
    </WhoWeAreContext.Provider>
  );
};

export default WhoWeAreContext;
