import React from 'react'
import "./Buttons.css"
import { Menu, Dropdown } from 'antd';
import arrowRight from '../../assets/geometry/arrowRight.svg'
import arrowLeft from '../../assets/geometry/arrowLeft.svg'
import arrowButton from '../../assets/geometry/arrowButton.svg'
import arrowUpPapyrus from '../../assets/geometry/arrowUpPapyrus.svg'
import { Submenu } from '../../context/HeaderContext'
import { Link } from "react-router-dom";

interface ButtonProps{
    type: string;
    text: string;
    styleType?: string; // filled, unfilled, filled-inversed, unfilled-inversed
    to?: string;
    dropdownMenu?: JSX.Element;
    number?: number;
    selectedButton?: number;
    mobileFullLength?: boolean;

    menu?: Submenu[];   // only for header buttons
    setOfIndexes?: number[]; // only for header buttons
    setCurrentItems?: (items: string[]) => void; // only for header buttons

    animationText?: string;
}

const menu = (
    <Menu>
        <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href="/">
            1st menu item
        </a>
        </Menu.Item>
        <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href="/">
            2nd menu item
        </a>
        </Menu.Item>
        <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" href="/">
            3rd menu item
        </a>
        </Menu.Item>
    </Menu>
);

export const CustomButton: React.FC<ButtonProps> = (props) => { 


    switch(props.type){

        // All the buttons with links //
        case "link": 

            switch(props.styleType){

                case "unfilled":
                    return(
                        <Link className={"Buttons-Link"  + props.mobileFullLength ? "FullLengthButton" : ""} to={props.to || "/404"}>
                            <button className="Buttons-Unfilled">
                                <span className="Buttons-Unfilled-Text">{props.text}</span>
                            </button>
                        </Link>
                    )

                
                case "unfilled-header":
                    return(
                        <Link className={"Buttons-Link"  + props.mobileFullLength ? "FullLengthButton" : ""} to={props.to || "/404"}>
                            <button className="Buttons-Unfilled-Header">
                                <span className="Buttons-Unfilled-Text">{props.text}</span>
                            </button>
                        </Link>
                    )

                case "unfilled-inversed":
                    return(
                        <Link className={"Buttons-Link"  + props.mobileFullLength ? "FullLengthButton" : ""} to={props.to || "/404"}>
                            <button className="Buttons-Unfilled-Inversed">
                                <span className="Buttons-Unfilled-Text">{props.text}</span>
                            </button>
                        </Link>
                    )
                
                case "unfilled-outlined":
                    return(
                        <Link className={"Buttons-Link"  + props.mobileFullLength ? "FullLengthButton" : ""} to={props.to || "/404"}>
                            <button className="Buttons-Unfilled-Outlined">
                                <span className="Buttons-Unfilled-Text">{props.text}</span>
                            </button>
                        </Link>
                    )

                case "filled":
                    return(
                        <Link className={"Buttons-Link"  + props.mobileFullLength ? "FullLengthButton" : ""} to={props.to || "/404"}>
                            <button className="Buttons-Filled">
                                <span className="Buttons-Filled-Text">{props.text}</span>
                            </button>
                        </Link>
                    )

                
                case "filled-inversed": 
                    return(
                        <Link className={"Buttons-Link" + props.mobileFullLength ? "FullLengthButton" : ""} to={props.to || "/404"}>
                            <button className="Buttons-Filled-Inversed">
                                <span className="Buttons-Filled-Text">{props.text}</span>
                            </button>
                        </Link>
                    )

                case "filled-inversed-arrow": 
                return(
                    <Link className={"Buttons-Link ButtonLinkArrow" + props.mobileFullLength ? "FullLengthButton" : ""} to={props.to || "/404"}>
                        <button className="Buttons-Filled-Inversed">
                            <span className="Buttons-Filled-Text"><span className='ButtonLinkArrow'>{props.text}</span> <img alt="Arrow Button" src={arrowButton}></img></span>
                        </button>
                    </Link>
                )
                
                case"Line":
                    return(
                        <button className={props.number === props.selectedButton ? "Buttons-Line" : "Buttons-Line-Focused"}>
                            <span className="Buttons-Filled-Text">{props.text}</span>
                        </button>
                    )

                case"Left-Arrow":
                    return(
                        <img alt="Left Arrow" src = {arrowLeft}></img>
                        
                    )
                case"Right-Arrow":
                    return(
                        <img alt="Right Arrow" src = {arrowRight}></img>
                    )

                default:
                    return(
                        <Link className={"Buttons-Link" + props.mobileFullLength ? "FullLengthButton" : ""} to={props.to || "/404"}>
                            <button className="Buttons-Filled">
                                <span className="Buttons-Filled-Text">{props.text}</span>
                            </button>
                        </Link>
                    )
            }

        case "external_link": 

            switch(props.styleType){

                case "unfilled":
                    return(
                        <a className={"Buttons-Link" + props.mobileFullLength ? "FullLengthButton" : ""} href={props.to} target="_blank" rel="noopener noreferrer">
                            <button className="Buttons-Unfilled">
                                <span className="Buttons-Unfilled-Text">{props.text}</span>
                            </button>
                        </a>
                    )

                case "unfilled-inversed":

                    if (props.to?.startsWith("https://pangolino.org/")) {
                        return(
                            <a className={"Buttons-Link" + props.mobileFullLength ? "FullLengthButton" : ""} href={props.to} download={props.to?.substring(props.to?.lastIndexOf("/") + props.to?.length - props.to?.lastIndexOf("/"))} target='_blank' rel="noreferrer">
                                <button className="Buttons-Unfilled-Inversed">
                                    <span className="Buttons-Unfilled-Text">{props.text}</span>
                                </button>
                            </a>
                        )
                    } else { 
                        return(                      
                            <a className={"Buttons-Link" + props.mobileFullLength ? "FullLengthButton" : ""} href={props.to} target="_blank" rel="noopener noreferrer">
                                <button className="Buttons-Unfilled-Inversed">
                                    <span className="Buttons-Unfilled-Text">{props.text}</span>
                                </button>
                            </a>
                        )
                    }

                case "unfilled-outlined":
                    return(
                        <a className={"Buttons-Link" + props.mobileFullLength ? "FullLengthButton" : ""} href={props.to} target="_blank" rel="noopener noreferrer">
                            <button className="Buttons-Unfilled-Outlined">
                                <span className="Buttons-Unfilled-Text">{props.text}</span>
                            </button>
                        </a>
                    )

                case "filled":
                    return(
                        <a className={"Buttons-Link" + props.mobileFullLength ? "FullLengthButton" : ""} href={props.to} target="_blank" rel="noopener noreferrer">
                            <button className="Buttons-Filled">
                                <span className="Buttons-Filled-Text">{props.text}</span>
                            </button>
                        </a>
                    )

                case "filled-header":
                    return(
                        <a className={"Buttons-Link" + props.mobileFullLength ? "FullLengthButton" : ""} href={props.to} target="_blank" rel="noopener noreferrer">
                            <button className="Buttons-Filled-Header">
                                <span className="Buttons-Filled-Text">{props.text}</span>
                            </button>
                        </a>
                    )
                
                case "filled-header-animation":
                    return(
                        // eslint-disable-next-line
                        <a className={"Buttons-Link" + props.mobileFullLength ? "FullLengthButton" : ""} href={'javascript:function() { return false; }'}>
                            <div className='Buttons-Filled-HeaderContainer-Animated'>
                                <button className="Buttons-Filled-Header-Animated">
                                    <span className="Buttons-Filled-Header-Animated-Preview">{props.text}</span>
                                    <span className="Buttons-Filled-Header-Animated-HiddenText">{props.animationText}</span> 
                                </button>
                            </div>
                        </a>
                    )
                
                case "filled-inversed": 

                    if (props.to?.startsWith("https://pangolino.org/")) {
                        return(
                            <a className={"Buttons-Link" + props.mobileFullLength ? "FullLengthButton" : ""} href={props.to} download={props.to?.substring(props.to?.lastIndexOf("/") + props.to?.length - props.to?.lastIndexOf("/"))} target='_blank' rel="noreferrer">
                                <button className="Buttons-Filled-Inversed">
                                    <span className="Buttons-Filled-Text">{props.text}</span>
                                </button>
                            </a>
                        )
                    } else {
                        return(
                            <a className={"Buttons-Link" + props.mobileFullLength ? "FullLengthButton" : ""} href={props.to} target="_blank" rel="noopener noreferrer">
                                <button className="Buttons-Filled-Inversed">
                                    <span className="Buttons-Filled-Text">{props.text}</span>
                                </button>
                            </a>
                        )
                    }

                case "filled-inversed-arrow": 
                return(
                    <a className={"Buttons-Link"  + props.mobileFullLength ? "FullLengthButton" : ""} href={props.to} target="_blank" rel="noopener noreferrer">
                        <button className="Buttons-Filled-Inversed">
                            <span className="Buttons-Filled-Text"><span className='ButtonLinkArrow'>{props.text}</span> <img alt="Arrow Button" src={arrowButton}></img></span>
                        </button>
                    </a>
                )
                
                case"Line":
                    return(
                        <button className={props.number === props.selectedButton ? "Buttons-Line" : "Buttons-Line-Focused"}>
                            <span className="Buttons-Filled-Text">{props.text}</span>
                        </button>
                    )

                case"Left-Arrow":
                    return(
                        <img alt="Left Arrow" src = {arrowLeft}></img>
                        
                    )
                case"Right-Arrow":
                    return(
                        <img alt="Right Arrow" src = {arrowRight}></img>
                    )

                default:
                    return(
                        <Link className={"Buttons-Link" + props.mobileFullLength ? "FullLengthButton" : ""} to={props.to || "/404"}>
                            <button className="Buttons-Filled">
                                <span className="Buttons-Filled-Text">{props.text}</span>
                            </button>
                        </Link>
                    )
            }

        case "dropdown":

            switch(props.styleType){

                case "unfilled":
                    return(
                        <Dropdown overlay={props.dropdownMenu || menu} placement="bottomRight" arrow>
                            <button className="Buttons-Unfilled">
                                <span className="Buttons-Unfilled-Text">{props.text}</span>
                            </button>
                        </Dropdown>
                    )

                case "unfilled-header":
                    return(
                        <button className="Buttons-Unfilled-Header">
                            <div>
                                <p className="Buttons-Unfilled-Header-Text">{props.text} <img alt="Arrow Up" src={arrowUpPapyrus}></img></p> 
                            </div>
                            
                            <span className="Buttons-Unfilled-Header-Dropdown-Menu">
                                {props.menu?.map((item, index) => {
                                    let curIndex = props.setOfIndexes?.[index] ?? 0
                                    return(
                                        <span onClick={() => props.setCurrentItems?.([curIndex.toString()])}>
                                            <Link to={item.link || "/404"}>
                                                <div key={index} className="Buttons-Unfilled-Header-Dropdown-Menu-Item">
                                                    <p className="Buttons-Unfilled-Header-Dropdown-Menu-Item-Text">{item.name}</p>
                                                </div>
                                            </Link>
                                        </span>
                                    )
                                }
                                )}
                            </span>
                        </button>
                    )

                default:
                    return(
                        <Link className="Buttons-Link" to={props.to || "/404"}>
                            <button className="Buttons-Filled">
                                <span className="Buttons-Filled-Text">{props.text}</span>
                            </button>
                        </Link>
                    )
            }

        // Standard buttons with NO links //
        default:

            switch(props.styleType){

                case "unfilled":
                    return(
                        <button className="Buttons-Unfilled" type="button">
                             <span className="Buttons-Unfilled-Text">{props.text}</span>
                        </button>
                    )

                case "unfilled-inversed":
                    return(
                        <button className="Buttons-Unfilled-Inversed" type="button">
                                <span className="Buttons-Unfilled-Text">{props.text}</span>
                        </button>
                    )
                case "unfilled-outlined":
                    return(
                        <button className="Buttons-Unfilled-Outlined" type="button">
                                <span className="Buttons-Unfilled-Text">{props.text}</span>
                        </button>
                    )
                case "filled":
                    return(
                        <button className="Buttons-Filled" type="button">
                             <span className="Buttons-Filled-Text">{props.text}</span>
                        </button>
                    )

                case "filled-inversed":
                    return(
                        <button className="Buttons-Filled-Inversed" type="button">
                             <span className="Buttons-Filled-Text">{props.text}</span>
                        </button>
                    )

                case "filled-inversed-arrow": 
                return(
                    <span className={"Buttons-Link ButtonLinkArrow" + props.mobileFullLength ? "FullLengthButton" : ""}>
                        <button className="Buttons-Filled-Inversed">
                            <span className="Buttons-Filled-Text"><span className='ButtonLinkArrow'>{props.text}</span> <img alt="Arrow Button" src={arrowButton}></img></span>
                        </button>
                    </span>
                )

                case"Line":
                    return(
                    
                        <button className={props.number === props.selectedButton ? "Buttons-Line-Focused" : "Buttons-Line"}>
                            <span className="Buttons-Filled-Text">{props.text}</span>
                        </button>
                    
                    )

                case"Left-Arrow":
                    return(
                        <img alt="Left Arrow" src = {arrowLeft}></img>
                    )

                case"Right-Arrow":
                    return(
                        <img alt="Right Arrow" src = {arrowRight}></img>
                    )

                default:
                    return(
                        <button className="Buttons-Filled">
                             <span className="Buttons-Filled-Text">{props.text}</span>
                        </button>
                    )
            }
    }

}