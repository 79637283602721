import './awarenessBody.css'
import React from 'react';
import { Body } from "../../../context/AwarenessContext"

export const AwarenessBody: React.FC<Body> = (props) => {

  return(
    <>
    <div className="Awareness-Description-Container">
        <h1>{props.title}</h1>
        <p>{props.description}</p>
    </div>

    </>
  )
}

