import './PangolinFactsMain.css';
import { Body } from "../../../context/PangolinFactsContext";

export const PangolinFactsMain: React.FC<Body> = (props) => { 

  return (
    <div className="PangolinsFactsMain-Body">
        <h1 className="PangolinsFactsMain-Body-MobileHeader">
            {props.title}
        </h1>
        <img alt="Facts Art" src={props.description_image}/>
        <div>
            <h1 className="PangolinsFactsMain-Body-DesktopHeader">
                {props.title}
            </h1>
            <p>
                {props.description}
            </p>
        </div>
    </div>
  );
}
