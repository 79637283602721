import './IllegalTrade.css';



interface IllegalTradeProps {
    title: string;
    description: string;
    link_word: string;
    link_url: string;
    link_postfix: string;
}

export const IllegalTrade: React.FC<IllegalTradeProps> = (props) => {
    return(
        <div className="IllegalTrade-Container">
            <h1>{props.title}</h1>
            <p>
                {props.description + " "} 
                <a href={props.link_url}>
                    <span>
                        {props.link_word}
                    </span>
                </a> 
                {props.link_postfix}
            </p>
        </div>
    )
}