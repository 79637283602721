import './WhoWeAre.css';
import { TitleSection2 } from '../../components/generic/TitleSection2';
import { Funders } from './components/Funders';
import { Person } from './components/Person';
import { useContext } from 'react';
import WhoWeAreContext, { WhoWeAreContextInterface } from '../../context/WhoWeAreContext';
import { Helmet } from 'react-helmet-async';
// added sections which will be the directives for the dropdown menu
export function WhoWeAre(): JSX.Element {

    const { top, team, board_of_trustees, funders } = useContext(WhoWeAreContext) as WhoWeAreContextInterface;


    return (
        <div className="WhoWeAre-Body">

            {/* @ts-ignore */}
            <Helmet>
                <title>About Us - Pangolino.org</title>
                <meta name="description" content='Learn about our team and funders who help to preserve pangolins.'></meta>
                <link rel="canonical" href="/about"></link>
            </Helmet>
            <TitleSection2
                title={top.title}
                description={top.description}
            />
            <div className="WhoWeAre-Body-Team">
                <h1>{team.title}</h1>
                <div className="WhoWeAre-Body-Team-TeamContainer"> 
                    {team.people.map((person: any, index: number) => {
                        return (<Person link={team.image_link} person={person}/>)
                    })} 
                </div>
            </div>
            <div className="WhoWeAre-Body-Team-Background">
                <div className="WhoWeAre-Body-Team" id="WhoWeAre-Body-Team-Trustees">
                    <h1>{board_of_trustees.title}</h1>
                    <div className="WhoWeAre-Body-Team-TeamContainer"> 
                        {board_of_trustees.people.map((person: any, index: number) => {
                            return (<Person link={board_of_trustees.image_link} person={person}/>)
                        })} 
                    </div>
                </div>
            </div>
            <Funders {...funders} />
        </div>
    )
}