import './Funders.css';
import Image from '../../../assets/images/wildlife_conservation_network.svg';
import { Carousel } from 'antd';
import ReactDOM from 'react-dom';
import React, { useEffect, useRef } from 'react';
import { Funders as FundersInterface } from '../../../context/WhoWeAreContext'


export const Funders: React.FC<FundersInterface> = (props) => {
    const containerRef = useRef(null);

    const carousel = (
        <Carousel autoplay>
            {props.images.map((image: any, index: number) => {
                return (
                <div className='Funders-Carousel-Container'>
                    <img src={props.images_link + image|| Image} alt="A founder"></img>
                </div>)
            })}
        </Carousel>
      );
    
    useEffect (() => {
        ReactDOM.render(carousel, containerRef.current);
    })
      
    return(
        <div className="Funders-BackgroundContainer">
            <div className="Funders-Container">
                <h1>{props.title}</h1>


                <div className='Funders-Container-ImagesContainer'>

                    {props.images.map((image: any, index: number) => {
                        return (
                        <div>
                            <img src={props.images_link + image|| Image} alt="A founder"></img>
                        </div>)
                    })}
                
                </div>

                <div ref={containerRef} className='Funders-Container-ImagesContainer-Mobile'>
                
                </div>

            </div>  
        </div>
    )
}