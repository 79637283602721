import React from 'react'
import './TitleSection2.css'

interface TitleSection2Props {
    title: string;
    description: string;
}

export const TitleSection2: React.FC<TitleSection2Props> = (props) => {

    return(
        <div className='TitleSection2-body'>
            <div className='TitleSection2-body-flex'>
                <h1 className='TitleSection2-body-flex-title'>
                    {props.title}
                </h1>
                <p className='TitleSection2-body-flex-description'>
                    {props.description}
                </p>
            </div>
        </div>
    )
}
