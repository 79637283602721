import {FC, createContext, useState, useEffect } from 'react'

import data from '../content/pages/careers.json'

export interface Top {
    title: string;
    description: string;
}

export interface Item {
    title: string;
    description: string;
    link: string;
    date: string;
    tag: string;
    sortID: number;
}

export interface Current {
    title: string;
    items: Item[];
}


export interface CareerContextInterface {
    top: Top;

    noItemsFirstLine: string;
    noItemsSecondLine: string;

    invalid_date: string;
    current: Current;  
    closed: Current;

    pageCounter: number[];
    itemsToShow: Item[];
    ITEMS_PER_PAGE: number;
    totalItems: number;
}


const CareerContext = createContext<CareerContextInterface | null>(null);

export const CareersProvider: FC<any> = ({ children }) => { 

  const top: Top = data.top

  const noItemsFirstLine : string = data.noItemsFirstLine;
  const noItemsSecondLine : string = data.noItemsSecondLine;

  const invalid_date : string = data.invalid_date



  const current : Current = data.current
  const closed : Current = data.closed


  const [pageCounter, setPageCounter] = useState<number[]>([]);
  const [itemsToShow, setItemsToShow] = useState<Item[]>([]);
  const ITEMS_PER_PAGE = data.ITEMS_PER_PAGE;
  const [totalItems, setTotalItems] = useState<number>(0);

 
  useEffect(() => {

    const ITEMS_PER_PAGE = data.ITEMS_PER_PAGE;


    function setAllPages(everything: Item[]){
      let temp: [number] = [-1];
      for ( let i = 0; i < everything.length ; i++ ) {
        if (i % ITEMS_PER_PAGE === 0) {
          temp.push(i / ITEMS_PER_PAGE + 1);
        }
      }
      temp.shift()
      setPageCounter(temp);
    }
  
    // This function is called when the page is first loaded
    function initialLoad(){


      let temp = [...data.current.items]


      console.log(temp)
      
      let temp2 = temp.sort( function(a, b) {
        // @ts-ignore: Property 'sortID' does not exist on type 'never'.
        return b.sortID - a.sortID;
      })

      console.log(temp2)
      setItemsToShow(temp2)
      setAllPages(temp2)
      setTotalItems(data.current.items.length + data.closed.items.length)
    }

    initialLoad()
  }, [])

 

  return (
    <CareerContext.Provider
      value={{
        top,
        noItemsFirstLine,
        noItemsSecondLine,
        invalid_date,
        current,
        closed,
        pageCounter,
        itemsToShow,
        ITEMS_PER_PAGE,
        totalItems,
      }}  
    >
      {children}
      
    </CareerContext.Provider>
  )
}

export default CareerContext

