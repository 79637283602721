import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import style from './markdown-styles.module.css';
import './MarkdownPage.css'
import { Item as BlogPost } from "../../../context/CommunicationsContext";

interface MarkdownPageInterface{
    blog: string;
    currentPost?: BlogPost;
    imagesFolder: string;
}

export const MarkdownPage: React.FC<MarkdownPageInterface> = (props) => { 

  const [content, setContent] = useState("");


  useEffect(() => {
    fetch("https://raw.githubusercontent.com/DFGPangolinoConservation/Pangolino-Content/main/pages/blogs/" + props.blog)
      .then((res) => res.text())
      .then((text) => setContent(text));
      

  }, [props.blog]);

  return (
    <div className="MarkdownPage-MainContainer">
      <div className="MarkdownPage" id="MarkdownPageID">
        <h1 className="MarkdownPage-Title">
          {props.currentPost?.title}
        </h1>
        <h3>
          By <span>{props.currentPost?.author}</span> on {props.currentPost?.date}
        </h3>
        {props.currentPost?.post_image !== undefined && (
        <img alt="Post" src={props.imagesFolder + props.currentPost?.post_image}/>)}
        <div className="MdContainer">
          <ReactMarkdown 
            remarkPlugins={[remarkGfm]} 
            className={style.reactMarkDown}
          >
            {content}
          </ReactMarkdown>
        </div>
        <div className="AuthorContainer">
          {props.currentPost?.author_image !== undefined && (
            <img alt="Author" src={props.imagesFolder + props.currentPost?.author_image}>
            </img>
          )}
          <h1>
            {props.currentPost?.author}
          </h1>
          <h3>
            {props.currentPost?.author_title}
          </h3>
          <p>
            {props.currentPost?.author_description}
          </p>
        </div>
      </div>
    </div>
  );
};