import './Converter.css';
import { TitleSectionC } from './components/TitleSectionC';
import { IllegalTrade } from './components/IllegalTrade';
import { HowToUseConverter } from './components/HowToUseConverter';
import { ScaleConverter } from './components/ScaleConverter';
import { useContext } from 'react';
import { Instructions } from './components/Instructions';
import ConverterContext, { ConverterContextInterface } from '../../context/ConverterContext';
import { Helmet } from 'react-helmet-async';

export function Converter(): JSX.Element{

    const {top, body1, body2, converter, instructions} = useContext(
        ConverterContext
    ) as ConverterContextInterface;

   
    return(
        <div className="Converter-Body">
            {/* @ts-ignore */}
            <Helmet>
                <title>Pangolin Scale Converter - Pangolino.org</title>
                <meta name="description" content="Understanding the scale of pangolin trafficking can inform effective decisions to curb the illicit trade. Use this page to calculate how many pangolins are affected."></meta>
                <link rel="canonical" href="/converter"></link>
            </Helmet>

            <TitleSectionC 
                title={top.title}
                description={top.description}
            />
            <IllegalTrade 
                title={body1.title} 
                description={body1.description}
                link_word={body1.link_word}
                link_url={body1.link_url}
                link_postfix={body1.link_postfix}
            >
            </IllegalTrade>
            <HowToUseConverter 
                title={body2.title} 
                description={body2.text} 
                buttonText={body2.button}   
            ></HowToUseConverter>     
            <ScaleConverter
                title1={converter.converter_title} 
                title2={converter.results_title}
                description={converter.results_text} 
                firstColumn={converter.results_columns.first}
                secondColumn={converter.results_columns.second}
                thirdColumn={converter.results_columns.third}
                firstRow={converter.results_rows.bb}
                secondRow={converter.results_rows.ss}
                thirdRow={converter.results_rows.wb}
            ></ScaleConverter>
            <Instructions 
                title={instructions.instructions_title}
                instructions={instructions.instructions_array} 
            >
            </Instructions>
        </div>
    )
}