import { CustomButton } from '../../../components/buttons/Buttons';
import './HowToUseConverter.css';
import { scroller } from 'react-scroll'
//import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

interface HowToUseConverterProps {
    title: string;
    description: string;
    buttonText: string;
}

export const HowToUseConverter: React.FC<HowToUseConverterProps> = (props) => {
    return(
        <div className="HowToUseConverter-Container">
            <h1>{props.title}</h1>
            {props.description !== "" &&
                <p>
                    {props.description}
                </p>
            }
            
            <div onClick={() =>{ scroller.scrollTo('Instructions-Container', {
                duration: 1600,
                delay: 0,
                smooth: 'easeInOutQuart',
                })}}>
                <CustomButton 
                    type={"filled"}
                    text={props.buttonText}>     
                </CustomButton>
            </div>
        </div>
    )
}