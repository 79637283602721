import {FC, createContext } from 'react'
import { LeftRightCItem } from '../components/generic/GenericLeftRightC'
import data from '../content/pages/awareness_raising.json'

export interface AwarenessContextInterface  {
  top: Top;
  body: Body;
  materials: LeftRightCItem[];
}

export interface Top {
  title: string;
  description: string;
}

export interface Body {
  title: string;
  description: string;
}

const AwarenessContext = createContext<AwarenessContextInterface | null>(data);

export const AwarenessProvider: FC<any> = ({ children }) => { 

  // const [top, setTop] = useState<Top>(
  //   {
  //     title: "Loading...",
  //     description: "Loading..."

  //   }
  // )
  
  // const [body, setBody] = useState<Body>(
  //   {
  //     title: "Loading...",
  //     description: "Loading..."
  //   }
  // )

  // const [materials, setMaterials] = useState<LeftRightCItem[]>([
  //   {
  //     label: "Loading...",
  //     title: "Loading...",
  //     description: "Loading...",
  //     button_1_text: "Loading...",
  //     button_1_link: "Loading...",
  //     button_2_text: "Loading...",
  //     button_2_link: "Loading...",
  //     img: "",
  //     position_left: true
  //   }
  // ])


  // useEffect(() => {
    
  //   const fetchData = async () => {
  //     const response = await fetch(
  //       'https://raw.githubusercontent.com/DFGPangolinoConservation/Pangolino-Content/main/pages/awareness_raising.json'
  //     )
  //     const data: AwarenessContextInterface = await response.json()
  
  //     initialLoad(data)
  //   }
  
  //   // This function is called when the page is first loaded
  //   function initialLoad(data: AwarenessContextInterface){
  //     setTop(data.top)
  //     setBody(data.body)
  //     setMaterials(data.materials)
  //   }

  //   fetchData()
  // }, [])
 

  return (
    <AwarenessContext.Provider
      value={data}
    >
      {children}
      
    </AwarenessContext.Provider>
  )
}

export default AwarenessContext
